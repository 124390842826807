import React, { useState, useEffect, useContext } from "react";
import { useApolloClient } from "@apollo/react-hooks";
import { gql } from "apollo-boost";
import { LoadingContext } from "../context/LoadingContext";
import styled from "styled-components";
import ScrollAnimation from 'react-animate-on-scroll';
import { ProductCard } from "../ProductCard";

const StyledWrapper = styled.div`
  h2 {
    color: #f5e5c4;
    font-size: 16px;
    font-weight: 600;
    letter-spacing: 1.6px;
    text-transform: uppercase;
  }
`;

const GET_CUSTOM_RELATED_PRODUCT = gql`
  query getCustomRelatedProduct($handle: String!) {
    productByHandle(handle: $handle) {
      id
      title
      handle
      images(first: 1) {
        edges {
          node {
            transformedSrc(maxHeight: 575)
          }
        }
      }
      priceRange {
        minVariantPrice {
          amount
        }
      }
    }
  }
`;

const CustomRealtedProducts = ({ relatedHandles }) => {
  const [relatedProducts, setRelatedProducts] = useState([]);
  const { showLoading, hideLoading } = useContext(LoadingContext);
  const client = useApolloClient();

  useEffect(() => {
    showLoading();
    Promise.all(
      relatedHandles.map(handle =>
        client.query({
          query: GET_CUSTOM_RELATED_PRODUCT,
          variables: { handle }
        })
      )
    ).then(res => {
      hideLoading();
      setRelatedProducts(res);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [client,hideLoading,showLoading]);

  return (
    <div>
      {relatedProducts.length > 0 && (
        <ScrollAnimation animateIn='fadeIn' animateOnce={true}>
          <StyledWrapper className="row my-5">
            <div className="col-12 my-4">
              <h2>You Might Also Like</h2>
            </div>
            {relatedProducts.map(queryResult => {
              const product = queryResult.data.productByHandle;
              return (
                  product &&
                <ProductCard
                    key={product.id}
                  product={product}
                  cardSize="col-6 col-md-4 col-lg-3"
                />
              );
            })}
          </StyledWrapper>
        </ScrollAnimation>
      )}
    </div>
  );
};

export default CustomRealtedProducts;

import React from "react";
import { Link } from 'react-router-dom';

const NoRouteMatch = () => {
  return (
    <div className="container h-100 d-flex align-items-center justify-content-center">
      <div className="row">
        <div className="col-12 text-center">
          <h3 style={{ color: "#ffffff" }}>Sorry, we couldn't find what you were looking for.</h3>
          <Link to="/shop">
            <button style={{ width: 260 }} className="btn btn-golden my-4">VISIT THE SHOP</button>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default NoRouteMatch;

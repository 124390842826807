import React, { useState, useEffect } from "react";
import AliceCarousel from "react-alice-carousel";
import "react-alice-carousel/lib/alice-carousel.css";
import StyledWrapper from "./styles";


const Carousel = ({ images }) => {
  const [padding, setPadding] = useState(() => {
    if (window.innerWidth < 1200) {
      return 0;
    } else if (window.innerWidth >= 1200 && window.innerWidth < 1300) {
      return 120;
    } else if (window.innerWidth >= 1300 && window.innerWidth < 1440) {
      return 180;
    } else if (window.innerWidth >= 1440) {
      return 240;
    }

  });

  const calculatePadding = () => {
    if (window.innerWidth < 1200) {
      setPadding(0);
    } else if (window.innerWidth >= 1200 && window.innerWidth < 1300) {
      setPadding(120);
    }  else if (window.innerWidth >= 1300 && window.innerWidth < 1440) {
      setPadding(180);
    }
    else if (window.innerWidth >= 1440) {
      setPadding(240);
    }
  };

  //Handles mouse click-dragging to navigate between carousel items
  const handleDragStart = (e) => e.preventDefault();

  useEffect(() => {
    window.addEventListener("resize", () => {
      calculatePadding();
    });
    return () => {
      window.removeEventListener("resize", () => calculatePadding());
    };
  }, []);

  
  const items = images.map((image,index) => <img key={index} src={image} className="carousel-img img-fluid" alt="" onDragStart={handleDragStart} />)

  return (
    <div>
      <StyledWrapper className="text-right">
        <AliceCarousel
          mouseTracking
          touchTracking
          infinite = {true}
          items = {items}
          paddingLeft= {padding}
          activeIndex={images.length-1}
        >
        </AliceCarousel>
      </StyledWrapper>
    </div>
  );
};

export default Carousel;

import React from "react";
import Hero from "./Hero";
import Issue from "./Issue";
import Issue2 from "./Issue2";
import Issue3 from "./Issue3";
import styled from "styled-components";

const StyledCavities = styled.div`
  font-family: "Kollektif", "Volte", sans-serif;
  --red: #ff6260;
  --yellow: #ffeb8d;

  img.mag {
    max-width: 422px;
    @media (max-width: 768px) {
      width: 100%;
    }
  }
`;

const index = () => {
  return (
    <StyledCavities>
      <Hero />
      <Issue2 />
      <Issue />
      <Issue3 />
    </StyledCavities>
  );
};

export default index;

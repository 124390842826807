import React, { useContext } from "react";
import { Transition } from 'react-spring/renderprops';
import { TransitionContext } from "./context/TransitionContext";

const ProductCardTransition = () => {
  const {
    showingTransition, movingToCarouselPosition,
    startX, startY, startWidth, startHeight,
    endX, endY, endWidth, endHeight, imgUrl,
  } = useContext(TransitionContext);

  let oldPosition;
  if(movingToCarouselPosition) {
    const oldPositionOb = document.getElementsByClassName("initial")[0];
    if(oldPositionOb) {
      oldPosition = oldPositionOb.getBoundingClientRect() || {
        x: 0,
        y: 0,
        width: 0,
        height: 0
      };
    }
    
  }
  if (showingTransition) {
    return <div
      style={{
        position: "fixed",
        width: "100%",
        height: "100%",
        zIndex: 1,
        background: "#000000",
        overflow: "hidden"
      }}
    >
      {
        !movingToCarouselPosition &&
          <img
            className="initial"
            style={{
              width: `${startWidth}px`,
              height: `${startHeight}px`,
              top: `${startY}px`,
              left: `${startX}px`,
              position: "absolute",
            }}
            src={imgUrl}
            alt=""
          />
      }
      {
        movingToCarouselPosition &&
        <Transition
          items={showingTransition}
          from={{ transform: `translate3d(${oldPosition? oldPosition.left: 0}px, ${oldPosition? oldPosition.top: 0}px, 0)`, width: `${oldPosition? oldPosition.width: 0}px`, height: `${oldPosition? oldPosition.width: 0}px` }}
          enter={{ opacity: 1, transform: `translate3d(${endX}px, ${endY}px, 0)`, width: `${endWidth}px`, height: `${endHeight}px` }}
          leave={{ opacity: 0 }}>
          {
            show => show && (props =>
              <img
                style={{
                  ...props
                }}
                src={imgUrl}
                alt=""
              />)
          }
        </Transition>
      }
    </div>
  }
  return null;
};

export default ProductCardTransition;

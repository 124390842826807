import React, {useEffect} from "react";
import FeaturedCollecton from "./FeaturedCollection";
import styled from "styled-components";
import ProductsByCollection from "./ProductsByCollection";
import ReactPixel from "react-facebook-pixel";
import { FEATURED_COLLECTION_HANDLE } from "../../utils";

const StyledWrapper = styled.div``;

const Shop = () => {

    useEffect( () => {
        ReactPixel.pageView();
    })

  return (
    <StyledWrapper>
      <FeaturedCollecton handle={FEATURED_COLLECTION_HANDLE} />
      <ProductsByCollection handle="tablets" />
      <ProductsByCollection handle="preserves" />
      <ProductsByCollection handle="bake-along" />
    </StyledWrapper>
  );
};

export default Shop;

import { toast } from "react-toastify";
import { gql } from "apollo-boost";

const stringifyPrice = (price) => {
  price = Math.round(price);
  price = price.toString();
  price = price.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  price = "₹ " + price;
  return price;
};

const notifyAddedToCart = () =>
  toast("Item added to cart", {
    position: toast.POSITION.BOTTOM_RIGHT,
    className: "toast-bronze-background",
    progressClassName: "toast-progress-bar",
    bodyClassName: "toast-body",
  });

const GET_PRODUCT_DETAILS = gql`
  query getProductByHandle($handle: String!) {
    productByHandle(handle: $handle) {
      id
      title
      descriptionHtml
      productType
      handle
      totalInventory
      variants(first: 10) {
        edges {
          node {
            id
            sku
            title
            price
            image {
              transformedSrc(maxHeight: 800)
            }
            metafield(namespace: "global", key: "contents") {
              key
              value
            }
          }
        }
      }
      images(first: 5) {
        edges {
          node {
            transformedSrc(maxHeight: 800)
          }
        }
      }
      metafields(first: 25, namespace: "global") {
        edges {
          node {
            key
            value
          }
        }
      }
    }
  }
`;

const FEATURED_COLLECTION_HANDLE = "autumn-winter-2019-20";

const GET_FEATURED_COLLECTION_TITLE_BY_HANDLE = gql`
  query getFeaturedCollectionProducts($handle: String!) {
    collectionByHandle(handle: $handle) {
      title
    }
  }
`;

const cavities_handles = {
  cavities_001: "cavities-001-the-issue-with-cake",
  cavities_002: "cavities-002-cheese-please",
};

// Returns short form of the title. (E.g : Spring / Summer 2022 (S/S 2022) -> S/S 2022)
// Input Ex: Spring / Summer 2022 (S/S 2022)
const getFeaturedCollectionShortTitle = (rawTitle) =>
  rawTitle.split("(")[1].split(")")[0];

// Returns the full title. (E.g : Spring / Summer 2022 (S/S 2022) -> Spring / Summer 2022)
// Input Ex: Spring / Summer 2022 (S/S 2022)
const getFeaturedCollectionFullTitle = (rawTitle) => rawTitle.split("(")[0];

export {
  stringifyPrice,
  notifyAddedToCart,
  GET_PRODUCT_DETAILS,
  FEATURED_COLLECTION_HANDLE,
  GET_FEATURED_COLLECTION_TITLE_BY_HANDLE,
  cavities_handles,
  getFeaturedCollectionShortTitle,
  getFeaturedCollectionFullTitle,
};

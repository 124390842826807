import React, { useEffect, useContext } from "react";
import ScrollAnimation from 'react-animate-on-scroll';
import { useQuery } from "@apollo/react-hooks";
import { gql } from "apollo-boost";
import { LoadingContext } from "../context/LoadingContext";
import styled from "styled-components";
import { ProductCard } from "../ProductCard";

const StyledWrapper = styled.div`
  h1 {
    color: #f5e5c4;
    font-size: 44px;
    @media (min-width: 768px) {
      font-size: 58px;
    }
    font-weight: 300;
    letter-spacing: -1px;

    &::after {
      width: 61px;
      height: 7px;
      background-color: #c3915b;
    }
  }
`;

const GET_COLLECTION_BY_HANDLE = gql`
  query getCollectionByHandle($handle: String!) {
    collectionByHandle(handle: $handle) {
      title
      products(first: 20) {
        edges {
          node {
            id
            title
            handle
            priceRange {
              minVariantPrice {
                amount
              }
            }
            images(first: 1) {
              edges {
                node {
                  transformedSrc(maxHeight: 575)
                }
              }
            }
          }
        }
      }
    }
  }
`;

const ProductsByCollection = ({ handle }) => {
  const { showLoading, hideLoading } = useContext(LoadingContext);

  useEffect(() => {
    showLoading();
  }, [showLoading]);

  const { loading, error, data } = useQuery(GET_COLLECTION_BY_HANDLE, {
    variables: { handle },
    onCompleted: data => {
      hideLoading();
    }
  });

  if (loading) return null;

  if (error) {
    return `Error! ${error}`;
  }

  const { title, products } = data.collectionByHandle;

  return (
    <StyledWrapper>
      {!loading && (
        <ScrollAnimation animateIn='fadeIn' animateOnce={true}>
          <div className="container">
          { products.edges.length > 0 &&
            <div className="row my-3">
              <div className="col-12">
                <div className="row">
                  <h2 className="col">{title}</h2>
                </div>
              </div>
            </div>
          }
            <div className="row">
              {products.edges.map(product => {
                return <ProductCard key={product.node.id} product={product.node} cardSize="col-6 col-md-4 col-lg-3" />;
              })}
            </div>
          </div>
        </ScrollAnimation>
      )}
    </StyledWrapper>
  );
};

export default ProductsByCollection;

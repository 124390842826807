import React, {useEffect} from "react";
import styled from "styled-components";
import ReactPixel from "react-facebook-pixel";

const StyledWrapper = styled.div`
  background-color: #3a383a;

  padding-top: 150px;
  padding-bottom: 150px;

  h1 {
    color: #f5e5c4;
    font-size: 28px;
    font-weight: 400;
  }

  .btn-golden {
    width: 240px;
  }

  .form-group {
    margin-bottom: 25px;
  }

  p.login {
    color: ${props => props.theme["lightGray"]};
    font-size: 16px;
    font-weight: 400;
    line-height: 20px;

    .login-link {
      color: #c3915b;
      font-size: 16px;
      font-weight: 600;
      letter-spacing: 0.5px;
      line-height: 16px;
      text-transform: uppercase;
    }
  }
`;

const Register = () => {

  useEffect( () => {
    ReactPixel.pageView();
  })

  return (
    <StyledWrapper>
      <div className="container">
        <div className="row">
          <div className="col-12 col-lg-5 mx-auto">
            <h1 className="mb-4">Registration</h1>
            <form action="">
              <div className="form-group">
                <label for="fullName">Full Name</label>
                <input
                  type="text"
                  className="form-control"
                  id="fullName"
                  aria-describedby="emailHelp"
                  // placeholder="Enter email"
                />
                {/* <small id="emailHelp" class="form-text text-muted">
                We'll never share your email with anyone else.
            </small> */}
              </div>
              <div className="form-group">
                <label for="phoneNumber">Phone Number</label>
                <input
                  type="text"
                  className="form-control"
                  id="phoneNumber"
                  aria-describedby="emailHelp"
                  // placeholder="Enter email"
                />
                {/* <small id="emailHelp" class="form-text text-muted">
                We'll never share your email with anyone else.
            </small> */}
              </div>
              <div className="form-group">
                <label for="exampleInputEmail1">Email</label>
                <input
                  type="email"
                  className="form-control"
                  id="exampleInputEmail1"
                  aria-describedby="emailHelp"
                  // placeholder="Enter email"
                />
                {/* <small id="emailHelp" class="form-text text-muted">
                We'll never share your email with anyone else.
            </small> */}
              </div>
              <div className="form-group">
                <label for="password">Create Password</label>
                <input
                  type="password"
                  className="form-control"
                  id="password"
                  aria-describedby="emailHelp"
                  // placeholder="Enter email"
                />
                {/* <small id="emailHelp" class="form-text text-muted">
                We'll never share your email with anyone else.
            </small> */}
              </div>
              <div className="form-group">
                <label for="confirmPassword">Re Enter Password</label>
                <input
                  type="email"
                  className="form-control"
                  id="confirmPassword"
                  aria-describedby="emailHelp"
                  // placeholder="Enter email"
                />
                {/* <small id="emailHelp" class="form-text text-muted">
                We'll never share your email with anyone else.
            </small> */}
              </div>
              <div className="text-center my-4">
                <button className="btn btn-golden mx-auto">
                  Register Now
                </button>
                <p className="login my-4">
                  Already have an account?{" "}
                  <span className="login-link ml-3">LOG IN</span>
                </p>
              </div>
            </form>
          </div>
        </div>
      </div>
    </StyledWrapper>
  );
};

export default Register;

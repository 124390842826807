import React, { createContext, useState } from 'react';

const TransitionContext = createContext({});
const TransitionProvider = ({ children }) => {

    const setShowTransition = (image) => {
        setTransitionState({
            ...transitionState,
            showingTransition: true,
            startX: image.x,
            startY: image.y,
            startWidth: image.width,
            startHeight: image.height
        });
    }

    const popUpImageOutOfCurrentPosition = (image, dim) => {
        setTransitionState({
            ...transitionState,
            imgUrl: image.src,
            showingTransition: true,
            startX: dim.x,
            startY: dim.y - 72,
            startWidth: image.width,
            startHeight: image.height,
            endX: image.x,
            endY: image.y - 72,
            endWidth: image.width,
            endHeight: image.height
        });
    }

    const moveImageToCarouselPosition = (image) => {
        setTransitionState({
            ...image,
            movingToCarouselPosition: true,
            showingTransition: true,
        });
    }

    const setHideTransition = () => {
        setTransitionState({
            ...transitionState,
            startX: 0,
            startY: 0,
            startWidth: 0,
            startHeight: 0,

            imgUrl: null,
            endX: 0,
            endY: 0,
            endWidth: 0,
            endHeight: 0,
 
            showingTransition: false,
            transitionCompleted: true,
            movingToCarouselPosition: false,
        });
    }

    const [transitionState, setTransitionState] = useState({
        movingToCarouselPosition: false,
        transitionCompleted: false,
        showingTransition: false,
        startX: 0,
        startY: 0,
        startWidth: 0,
        startHeight: 0,

        imgUrl: null,
        endX: 0,
        endY: 0,
        endWidth: 0,
        endHeight: 0,
        setShowTransition,
        setHideTransition,
        popUpImageOutOfCurrentPosition,
        moveImageToCarouselPosition,
    });

    return (
        <TransitionContext.Provider value={transitionState}>
            {children}
        </TransitionContext.Provider>
    )

};

export { TransitionContext, TransitionProvider };


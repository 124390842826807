import React, {useEffect, useContext, useState} from "react";
import {useQuery} from "@apollo/react-hooks";
import {gql} from "apollo-boost";
import {LoadingContext} from "../context/LoadingContext";
import styled from "styled-components";
import ScrollAnimation from 'react-animate-on-scroll';
import {FeaturedProductCard} from "../ProductCard";
import FeaturedBg from '../../assets/images/contours.svg';
import {useSpring, animated} from "react-spring";
import { getFeaturedCollectionFullTitle } from "../../utils";

const StyledWrapper = styled.div`

  #gradient {
    background: -moz-linear-gradient(top,  rgba(0, 0, 0, 0.4), rgba(10, 10, 10, 0), rgba(0, 0, 0, 0.8)), url(${FeaturedBg}) center center / cover fixed; /* FF3.6+ */
    background: -webkit-linear-gradient(top,  rgba(0, 0, 0, 0.4), rgba(10, 10, 10, 0), rgba(0, 0, 0, 0.8)), url(${FeaturedBg}) center center / cover fixed; /* Chrome10+,Safari5.1+ */
    background: -o-linear-gradient(top,  rgba(0, 0, 0, 0.4), rgba(10, 10, 10, 0), rgba(0, 0, 0, 0.8)), url(${FeaturedBg}) center center / cover fixed; /* Opera 11.10+ */
    background: -ms-linear-gradient(top,  rgba(0, 0, 0, 0.4), rgba(10, 10, 10, 0), rgba(0, 0, 0, 0.8)), url(${FeaturedBg}) center center / cover fixed; /* IE10+ */
    background: linear-gradient(to bottom,  rgba(0, 0, 0, 0.4), rgba(10, 10, 10, 0), rgba(0, 0, 0, 0.8)), url(${FeaturedBg}) center center / cover fixed; /* W3C */
  }

  h1 {
    color: #f5e5c4;
    font-size: 44px;
    @media (min-width: 768px) {
      font-size: 58px;
    }
    font-weight: 300;
    letter-spacing: -1px;

    margin-bottom: 90px;
    @media (min-width: 992px) {
      margin: 90px 0px 60px 0px;
    }

    &::after {
      width: 61px;
      height: 7px;
      background-color: #c3915b;
    }
  }

  .example-appear {
    opacity: 0.01;
  }

  .example-appear.example-appear-active {
    opacity: 1;
    transition: opacity 0.5s ease-in;
  }

  .hide-after {
    &::after {
      display: none;
    }
  }

  .top-picks {
    position: relative;
    left: 7%;
    padding-top: 60px;

    @media (max-width: 768px) {
      left: 0;
      margin-left: auto;
      margin-right: auto;
    }
  }
  
`;

const GET_FEATURED_COLLECTION_PRODUCTS = gql`
    query getFeaturedCollectionProducts($handle: String!) {
        collectionByHandle(handle: $handle) {
            title
            products(first: 20) {
                edges {
                    node {
                        id
                        title
                        handle
                        priceRange {
                            minVariantPrice {
                                amount
                            }
                        }
                        images(first: 1) {
                            edges {
                                node {
                                    transformedSrc(maxHeight: 575)
                                }
                            }
                        }
                        metafields(first: 20, namespace: "global") {
                            edges {
                                node {
                                    key
                                    value
                                }
                            }
                        }
                    }
                }
            }
        }
    }
`;

const FeaturedCollection = ({handle}) => {
    const {showLoading, hideLoading} = useContext(LoadingContext);
    const [showTitle, setShowTitle] = useState(false);
    const props = useSpring({
        transform: "scale(1)",
        from: {transform: "scale(0.5)"},
    });

    useEffect(() => {
        showLoading();
    }, [showLoading]);

    const {loading, error, data} = useQuery(GET_FEATURED_COLLECTION_PRODUCTS, {
        variables: {handle},
        onCompleted: data => {
            hideLoading();
        }
    });

    if (loading) return null;

    if (error) {
        return `Error! ${error}`;
    }

    return (
        <StyledWrapper className="mb-5">
            {!loading && (
                <ScrollAnimation animateIn='fadeIn' animateOnce={true} afterAnimatedIn={() => setShowTitle(true)}>
                    <section id="gradient">
                        <div className="container">
                            <animated.div className="row" style={props}>
                                <div className="col-12 text-center">
                                    <div className="row justify-content-center">
                                        <h1 className={`animate-underline col ${showTitle ? "" : "hide-after"}`}>
                                            {getFeaturedCollectionFullTitle(data.collectionByHandle.title)}
                                        </h1>
                                    </div>
                                </div>
                            </animated.div>
                            <div className="container">
                                <div className="row">
                                    <h2 className="top-picks">Top Picks</h2>
                                </div>
                            </div>
                            <div className="row">
                                {data.collectionByHandle.products.edges.map(product => {
                                    let subtitle = "";
                                    subtitle = product.node.metafields.edges.filter(field => field.node.key === "subtitle")[0] !== undefined ? product.node.metafields.edges
                                        .filter(field => field.node.key === "subtitle")[0].node.value : "";

                                    return (
                                        <FeaturedProductCard
                                            key={product.node.id}
                                            product={product}
                                            subtitle={subtitle}
                                            cardSize="col-md-6"
                                            style={props}
                                        />
                                    );
                                })}
                            </div>
                        </div>
                    </section>
                </ScrollAnimation>
            )}
        </StyledWrapper>
    );
};

export default FeaturedCollection;

import React, {useState} from "react";
import styled from "styled-components";
import {NavLink, Link, withRouter} from "react-router-dom";
import {useHistory} from "react-router-dom";
import CartBadge from "./CartBadge";
import WhatsApp from "../../assets/images/whatsapp-icon.svg";
import { useQuery } from "@apollo/react-hooks";
import { gql } from "apollo-boost";
import { FEATURED_COLLECTION_HANDLE, getFeaturedCollectionShortTitle, GET_FEATURED_COLLECTION_TITLE_BY_HANDLE } from "../../utils";

const StyledWrapper = styled.div`
  position: fixed;
  z-index: 12;
  background-color: black;
  @media (max-width: 992px) {
    margin-bottom: 20px;
  }

  font-weight: 400;
  font-size: 15px;
  letter-spacing: 2px;

  a,span[type="button"] {
    margin-right: 36px;
    background: none;
    -webkit-appearance: none;
    border: none;
    box-shadow: none;
    color: #c3915b;
  }

  a.logo-link {
    margin: 0 !important;
  }
  a.logo-link::before {
    display: none;
  }

  #sideMenu {
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    width: 100%;
    background-color: black;

    padding: 140px 30px;

    span,a {
      font-size: 20px;
      display: block;
      margin-bottom: 15px;
    }
  }

  .hamburger {
    outline: none;
    box-shadow: none;
  }

  .hamburger-box {
    span,
    span::after,
    span::before {
      border-radius: 0;
      background-color: white;
      width: 30px;
      height: 2px;
    }
  }

  .hamburger.is-active .hamburger-inner,
  .hamburger.is-active .hamburger-inner::before,
  .hamburger.is-active .hamburger-inner::after {
    background-color: white;
  }

  ol,
  ul {
    list-style: none;
  }

  li {
    display: inline-block;
    padding: 20px 0 20px;
  }

  a,span[type="button"] {
    text-decoration: none;
    position: relative;
    transition: color 0.1s, background-color 0.1s;
    cursor:pointer;
  }
  a:hover, span[type="button"]:hover {
    color: #c3915b;
  }
  a:focus,a:active,
   span[type="button"]:focus, span[type="button"]:active {
    color: #c3915b;
  }

  a::before, span[type="button"]::before {
    content: "";
    display: block;
    position: absolute;
    top: 100%;
    left: 0;
    height: 3px;
    width: 20px;
    background-color: #c3915b;
    -webkit-transform-origin: center top;
    transform-origin: center top;
    -webkit-transform: scale(0, 1);
    transform: scale(0, 1);
    transition: color 0.1s, -webkit-transform 0.2s ease-out;
    transition: color 0.1s, transform 0.2s ease-out;
    transition: color 0.1s, transform 0.2s ease-out,
      -webkit-transform 0.2s ease-out;
  }

  a:active::before {
    background-color: #c3915b;
  }

  a:hover::before,
  a:focus::before, 
  span[type="button"]:hover::before,
  span[type="button"]:focus::before{
    -webkit-transform-origin: center top;
    transform-origin: center top;
    -webkit-transform: scale(1, 1);
    transform: scale(1, 1);
  }

  a.active {
    &::before {
      background-color: #c3915b;
      -webkit-transform-origin: center top;
      transform-origin: center top;
      -webkit-transform: scale(1, 1);
      transform: scale(1, 1);
    }
  }
  
  .dropdown {
    position: relative;
  }

  .dropdown-content {
    display: none;
    position: absolute;
    background-color: black;
    min-width: 160px;
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
    padding: 24px 16px 16px 16px;
    z-index: -1;
  }
  
  .dropdown:hover .dropdown-content {
    display: block;
  }
  
  p.dropdown-link {
    color: white;
    cursor:pointer;
    &:hover {
      color: #c3915b;
    }
  }

  .shipping-banner {
    color: white;
    background-color: #c3915b;
  }
  
    .chat-icon {
    position: fixed;
    display: inline-block;
    right: 20px;
    bottom: 80px;
    z-index: 999;
    img {
      width: 40px;
      height: 40px;
    }
    }
`;

const GET_PRODUCT_DETAILS = gql`
    query product{
      collections(first: 10) {
        edges {
          node {
            title
            handle
            products(first: 20) {
              edges {
                node {
                  title
                  handle
                }
              }
            }
          }
        }
      }
    }
`

const Header = ({location: {pathname}}) => {

  const history = useHistory();
  const [sideMenuOpen, setSideMenuOpen] = useState(false);
  const [showMobileDropdown, setShowMobileDropdown] = useState(false);
  const [showBanner,setShowBanner]=useState(true)
  

  
  const { data } = useQuery(GET_PRODUCT_DETAILS) 
  
  const { data : collectionData } = useQuery(GET_FEATURED_COLLECTION_TITLE_BY_HANDLE, {
    variables: {handle: FEATURED_COLLECTION_HANDLE},
  });

  const getFeaturedNavDOM = () => {
    const title = getFeaturedCollectionShortTitle(collectionData.collectionByHandle.title);
    return (<p className="dropdown-link" onClick={() => history.push('/shop')}>{title ? title : "Featured"}</p>);
  }
 
  const productArray = [];

  React.useEffect(() => {
    if (data) {
      data.collections.edges.forEach((item) => {
        if (item.node.handle === "monthly-specials") {
          productArray.push(
            item.node.products.edges.map((product) =>
              (product.node.handle)
            )
          );
        }
      });
      if (productArray && productArray[0].includes(pathname.split("/")[2])) {
        setShowBanner(false);
      } else {
        setShowBanner(true);
      }
    }
  }, [pathname,data,productArray]);
  
    

  return (
      <StyledWrapper
          overlap={
            pathname === "/" || pathname === "/register" || pathname === "/cart"
                ? true
                : false
          }
          className="container-fluid px-0"
          id="header"
      >

          <div className="chat-icon" data-v-1726953c=""><a href="https://wa.me/919137801624" target="_blank"
                                                           aria-label="rootedobjects" rel="noopener noreferrer"
                                                           data-v-1726953c=""><img src={WhatsApp}
                                                                                   alt="" data-v-1726953c=""/></a>
          </div>
           

        {data &&  (pathname === '/specials' || pathname === '/specials/' ? <></> : showBanner === true ?  <div className="py-2 shipping-banner text-center">Now shipping pan India</div> : <></>)}
        
        {pathname !== "/" && (
            <div className="pt-4 px-4 px-lg-5 pb-lg-4">
              <div id="sideMenu" className={sideMenuOpen ? "" : "d-none"}>
                <span
                    type="button"
                    activeclassname="active"
                    onClick={() => setShowMobileDropdown(!showMobileDropdown)}
                >
                  Shop
                </span>
                <div id="mobileDropdown" hidden={showMobileDropdown ? false : true} className="pl-3">
                  <p className="dropdown-link" onClick={() => {
                    setSideMenuOpen(false);
                    history.push('/shop')
                  }}
                  >
                  A/W 2021
                  </p>
                  <p className="dropdown-link" onClick={() => {
                    setSideMenuOpen(false);
                    history.push('/specials')
                  }}>Cake</p>
                </div>
                <NavLink
                    activeclassname="active"
                    to="/about"
                    onClick={() => setSideMenuOpen(false)}>
                  About
                </NavLink>
                <NavLink
                    activeclassname="active"
                    to="/contact"
                    onClick={() => setSideMenuOpen(false)}>
                  Contact
                </NavLink>

                <a
                    href="https://account.etherchocolate.com/account"
                    onClick={() => setSideMenuOpen(false)}
                >
                  Login
                </a>
              </div>

              <div className="row">
                <div className="col-4 d-lg-none" style={{zIndex: 2}}>
                  <span
                      onClick={() => setSideMenuOpen(!sideMenuOpen)}
                      className={
                        "hamburger hamburger--squeeze p-0 " +
                        (sideMenuOpen ? "is-active" : "")
                      }
                  >
                <span className="hamburger-box">
                  <span className="hamburger-inner"></span>
                </span>
                  </span>
                </div>
                <div className="col-4 d-none d-lg-block" style={{zIndex: 2}}>
                  <div className="dropdown m-0 d-inline-block">
                    <span
                        activeclassname="active"
                        onClick={() => setSideMenuOpen(false)}
                        className="dropbtn"
                        type="button"
                    >
                      Shop
                      <div className="dropdown-content">
                        {collectionData && getFeaturedNavDOM()}
                        <p className="dropdown-link" onClick={() => history.push('/specials')}>Cake</p>
                      </div>
                    </span>
                  </div>
                  <NavLink
                      activeclassname="active"
                      to="/about"
                      onClick={() => setSideMenuOpen(false)}>
                    About
                  </NavLink>
                  <NavLink
                      activeclassname="active"
                      to="/contact"
                      onClick={() => setSideMenuOpen(false)}>
                    Contact
                  </NavLink>

                </div>
                <div className="col-4 text-center" style={{zIndex: 2}}>
                  {pathname !== "/" && (
                      <Link
                          className="logo-link"
                          to="/"
                          onClick={() => setSideMenuOpen(false)}
                      >
                        <img
                            style={{width: 110}}
                            className="img-fluid"
                            src="https://cdn.shopify.com/s/files/1/0227/1117/3156/files/Ether_logo.svg?79"
                            alt="logo"
                        />
                      </Link>
                  )}
                </div>
                <div className="col-4 text-right" style={{zIndex: 2}}>
                  <a
                      href="https://account.etherchocolate.com/account"
                      className="d-none d-lg-inline"
                  >
                    Login
                  </a>
                  <CartBadge/>
                </div>
              </div>
            </div>
        )}
      </StyledWrapper>
  );
};

export default withRouter(Header);

import React from "react";
import styled from "styled-components";
// import { stringifyPrice } from "../../utils";

const StyledWrapper = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  height: 100px;
  width: 280px;
  border: 1px solid
    ${props => (props.selected === true ? props.theme["sundance"] : "#272626")};

  img {
    align-self: center;
  }

  span.title {
    color: ${props => props.theme["lightGray"]};
    font-size: 16px;
    font-weight: 600;
    letter-spacing: 0.5px;
    text-transform: uppercase;
    display: block;
  }

  span.price {
    color: ${props => props.theme["lightGray"]};
    font-size: 18px;
    font-weight: 500;
  }
`;

const VariantBox = (props) => {
  const { variant } = props;
  return (
    <StyledWrapper {...props} className="px-3">
      <div className="d-block mx-auto">
        <span className="title">{variant.title}</span>
        {/* <span className="price">{variant.metafield.value} | {stringifyPrice(variant.price)}</span> */}
      </div>
    </StyledWrapper>
  );
};

export default VariantBox;

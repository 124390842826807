import React, { useContext, useState } from "react";
import { Col, Container, Row } from "reactstrap";
import styled from "styled-components";
import AliceCarousel from "react-alice-carousel";

import ReactGA from "react-ga";
import ReactPixel from "react-facebook-pixel";

import Issue001 from "../../assets/images/cavities/Issue001.png";
import Banner from "../../assets/images/cavities/Issue001-Banner.png";
import Pooja from "../../assets/images/cavities/Chef_Pooja.png";
import Shanaya from "../../assets/images/cavities/Chef_Shanaya.png";
import Vikas from "../../assets/images/cavities/Chef_Vikas.png";
import Dane from "../../assets/images/cavities/Chef_Dane.png";
import Prateek from "../../assets/images/cavities/Chef_Prateek.png";
import {
  cavities_handles,
  GET_PRODUCT_DETAILS,
  notifyAddedToCart,
} from "../../utils";
import { CartContext } from "../context/CartContext";
import { useQuery } from "@apollo/react-hooks";

const StyledSection = styled.section`
  background: white;
  color: black;
  padding: 6rem 0 0;
  text-align: center;

  img {
    position: relative;
    &.banner {
      top: 7.5rem;
    }
  }

  h1 {
    color: #ff6260;
    font-size: 54px;
    font-weight: 700;
    margin: 2.5em 0 1em;
  }

  p,
  h4 {
    font-size: 24px;
  }

  button {
    font-size: 18px;
    font-weight: 600;
    line-height: 1.2em;
    font-family: "Volte", sans-serif;
    text-transform: uppercase;
    border: 1px solid transparent;
    outline: none;
    background: var(--red);
    color: white;
    width: 230px;
    padding: 12px;
    &.outlined {
      background: transparent;
      color: var(--red);
      border-color: var(--red);
    }
  }

  .featured {
    background-color: var(--red);
    color: white;
    padding: 18rem 0 7rem;
    h3 {
      font-size: 34px;
      font-weight: 700;
      letter-spacing: 0.03em;
      text-transform: uppercase;
    }
    .carousel-img {
      width: 446px;
      height: 620px;
    }
  }

  @media (max-width: 768px) {
    padding: 4rem 0 0;
    text-align: left;

    h1 {
      font-size: 20px;
    }
    h4 {
      font-size: 16px;
      text-align: center;
    }
    p,
    button {
      font-size: 14px;
    }
    img.banner {
      top: 5rem;
      width: 100%;
    }
    .featured {
      padding: 10rem 0 2rem;
      h3 {
        font-size: 18px;
        text-align: center;
      }
    }
  }
  @media (max-width: 576px) {
    .featured img.carousel-img {
      width: 100vw;
      height: 140vw;
    }
  }

  .add_to_cart_btn {
    :disabled {
      opacity: 0.5;
      cursor: not-allowed;
    }
  }
`;

const Issue = () => {
  const { cartDispatch } = useContext(CartContext);

  const [selectedVariant, setSelectedVariant] = useState({
    id: "",
    sku: "",
    parentProductTitle: "",
    handle: "",
    title: "",
    price: "",
    image: "",
    deliveryDate: "",
  });

  const imgs = [
    <img src={Pooja} alt="" className="carousel-img" />,
    <img src={Shanaya} alt="" className="carousel-img" />,
    <img src={Vikas} alt="" className="carousel-img" />,
    <img src={Dane} alt="" className="carousel-img" />,
    <img src={Prateek} alt="" className="carousel-img" />,
  ];

  const { loading, error, data } = useQuery(GET_PRODUCT_DETAILS, {
    variables: { handle: cavities_handles.cavities_001 },
    onCompleted: (data) => {
      if (data.productByHandle !== null) {
        const { price, title } = data.productByHandle.variants.edges[0].node;
        setSelectedVariant({
          id: data.productByHandle.variants.edges[0].node.id,
          sku: data.productByHandle.variants.edges[0].node.sku,
          parentProductTitle: data.productByHandle.title,
          handle: cavities_handles.cavities_001,
          title: title,
          price: price,
          image: data.productByHandle.images.edges[0].node.transformedSrc,
        });
      }
    },
  });

  if (loading) return null;

  if (error) {
    return `Error! ${error}`;
  }

  let disableAddToCart = false;
  if (data.productByHandle === null) disableAddToCart = true;

  let title = "";
  let totalInventory = 0;
  if (data.productByHandle !== null) {
    title = data.productByHandle.title;
    totalInventory = data.productByHandle.totalInventory;
  }

  return (
    <StyledSection id="Issue001">
      <Container>
        <Row>
          <Col xs="12">
            <h4 className="mb-5 pb-md-3 mx-auto">
              ISSUE 001 // SEPTEMBER 2021
            </h4>
          </Col>
          <Col xs="9" md="12" className="mx-auto">
            <img src={Issue001} alt="" className="img-fluid mag" />
          </Col>
        </Row>
        <Row>
          <Col xs="12">
            <h1>THE ISSUE WITH CAKE</h1>
            <p className="mb-md-5 mb-3">
              In this issue of 'Way Too Many CAVITIES' I meet with 4 pastry
              chefs (plus, myself) that have each mastered their unique voice
              and style in the vast world of professional pastry. Working with
              the same chocolate (NOIR), I ask each of the chefs to create a
              basic dark chocolate slice cake. The idea here is to study how a
              very basic idea, in the hands of 5 very different experts, takes
              on subtly different forms. and from these differences, to endeavor
              a technical deep-dive into what makes the perfect dark chocolate
              slice cake.
            </p>
            <p className="mb-5">
              You can download a digital copy of the bulletin for free or
              support the bulletin by purchasing one of only 40 signed print
              copies (signed by all 5 chefs!).
            </p>
          </Col>
          <Col
            xs="9"
            md="12"
            className="mx-auto d-flex align-items-stretch justify-content-center flex-wrap flex-md-nowrap"
          >
            <button
              disabled={disableAddToCart || totalInventory === 0}
              className="add_to_cart_btn"
              onClick={() => {
                notifyAddedToCart();
                cartDispatch({
                  type: "ADD_ITEM",
                  payload: { ...selectedVariant, quantity: 1 },
                });
                ReactGA.event({
                  category: "User Button Click",
                  action: "Added Item To Cart",
                  label: `${title} - ${selectedVariant.title}`,
                });
                ReactPixel.track("AddToCart", {
                  content_ids: selectedVariant.sku,
                  content_name: selectedVariant.parentProductTitle,
                  content_type: "product",
                  currency: "INR",
                  value: selectedVariant.price,
                });
              }}
            >
              ADD TO CART
            </button>
            <a
              href="https://cdn.shopify.com/s/files/1/0227/1117/3156/files/CAVITIES_001.pdf?v=1630928249"
              target="_blank"
              rel="noreferrer noopener"
            >
              <button className="outlined ml-md-5 mt-md-0 mt-3">
                DIGITAL PDF
                <br />
                (Free Download)
              </button>
            </a>
          </Col>
        </Row>
      </Container>
      <img src={Banner} alt="" className="w-100 banner" />
      <div className="featured">
        <h3 className="mb-5 pb-md-3 pb-0">Featured Chefs</h3>
        <AliceCarousel
          autoWidth
          autoPlay
          autoPlayInterval={800}
          autoPlayStrategy="none"
          //animationEasingFunction='linear'
          animationDuration={1000}
          disableButtonsControls
          disableDotsControls
          infinite
          items={imgs}
          mouseTracking
        />
      </div>
    </StyledSection>
  );
};

export default Issue;

import React, { createContext, useState, useEffect, useReducer } from "react";

const CartContext = createContext();
const initialState = { items: {} };

const reducer = (state, action) => {
  switch (action.type) {
    case "ADD_ITEM": {
      const { id, quantity } = action.payload;
      if (state.items[id]) {
        return {
          ...state,
          items: {
            ...state.items,
            [id]: {
              ...state.items[id],
              quantity: state.items[id].quantity + quantity,
            },
          },
        };
      } else
        return {
          ...state,
          items: { ...state.items, [id]: action.payload },
        };
    }

    case "REDUCE_ITEM": {
      let id = action.payload;
      if (state.items[id]) {
        let quantity = state.items[id].quantity;
        if (quantity === 1) {
          const { [id]: value, ...remaining } = state.items;
          return {
            ...state,
            items: remaining,
          };
        } else {
          return {
            ...state,
            items: {
              ...state.items,
              [id]: {
                ...state.items[id],
                quantity: state.items[id].quantity - 1,
              },
            },
          };
        }
      } else return { ...state };
    }

    case "DELETE_ITEM": {
      const { [action.payload]: value, ...remaining } = state.items;
      return {
        ...state,
        items: remaining,
      };
    }

    case "CLEAR_CART": {
      return { ...state, items: {} };
    }

    default:
      return Error("Invalid action type");
  }
};

const CartProvider = ({ children }) => {
  const [cartState, cartDispatch] = useReducer(reducer, initialState);
  const [subtotal, setSubtotal] = useState(0);

  useEffect(() => setSubtotal(Object.values(cartState.items).reduce((acc, item) => acc + item.quantity * item.price, 0)), [cartState.items]);

  return <CartContext.Provider value={{ items: { ...cartState.items }, cartDispatch, subtotal }}>{children}</CartContext.Provider>;
};

export { CartProvider, CartContext };

import React from "react";
import styled from "styled-components";
import Instagram from "../../assets/images/instagram.svg";
import { Link } from "react-router-dom";

const StyledWrapper = styled.div`
  span {
    @media (min-width: 992px) {
      margin-right: 25px;
    }
  }

  span {
    a {
      font-size: 18px;
      @media (min-width: 992px) {
        font-size: 1rem;
      }
    }
  }

  a.legal {
    @media (min-width: 992px) {
      margin-left: 25px;
    }
    text-transform: uppercase;
    font-weight: 600;
    letter-spacing: 0.5px;
    font-size: 14px;
    @media (min-width: 992px) {
      font-size: 12px;
    }
  }

  .contact {
    font-weight: 600;
    color: white;
    font-size: 18px;
    @media (min-width: 992px) {
      font-size: 1rem;
    }
  }

  .color-light-gray {
    color: ${props => props.theme["lightGray"]};
  }
  
  .designed {
    font-size: 12px;
  }
  

}
  
`;

const Footer = () => {
  return (
    <StyledWrapper className="container-fluid px-lg-5 py-4 mt-auto" id="footer">

      <div className="row">
        <div className="col-lg-6 color-light-gray text-lg-left">
          <span className="contact d-block d-lg-inline my-4 my-lg-0">
            Contact:
          </span>
          <span className="d-block d-lg-inline my-4 my-lg-0">
            <a href="tel:+9191378 01624">+91 91378 01624</a>
          </span>
          <span className="d-block d-lg-inline my-4 my-lg-0">
            <a href="mailto:hello@etherchocolate.com">hello@etherchocolate.com</a>
          </span>
        </div>
        <div className="col-lg-6 text-lg-right">
          <a
            className="social d-block d-lg-inline mb-4 mb-lg-0"
            target="_blank"
            rel="noopener noreferrer"
            href="https://www.instagram.com/etherchocolate/"
          >
            <img src={Instagram} alt="instagram" />
          </a>
          <Link
            className="legal d-block d-lg-inline my-3 my-lg-0"
            to="/privacy-policy"
          >
            Privacy Policy
          </Link>
          <Link
            className="legal d-block d-lg-inline my-3 my-lg-0"
            to="/terms-and-conditions"
          >
            Terms & Conditions
          </Link>
          {
          <a className="designed d-block my-3 my-lg-0" href="https://1stmain.co"
             target="_blank" rel="noopener noreferrer">
            Designed and built by&nbsp; 1stMain
          </a>
        }
        </div>

      </div>
    </StyledWrapper>
  );
};

export default Footer;

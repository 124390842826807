import React, { Component } from "react";
import { Map, GoogleApiWrapper, Marker } from "google-maps-react";
import mapStyle from "./mapStyle";
import MarkerIcon from "../../assets/images/location icon.svg";

class CustomMap extends Component {
  render() {
    return (
      <Map
        google={this.props.google}
        zoom={16}
        styles={mapStyle}
        initialCenter={{ lat: 19.011912, lng: 72.851306 }}
        disableDefaultUI={true}
        zoomControl={true}
        fullscreenControl={false}
      >
        <Marker
          name="Ether Atelier Chocolat"
          position={{ lat: 19.011912, lng: 72.851306 }}
          icon={{
            url: MarkerIcon,
            anchor: new this.props.google.maps.Point(24, 24),
            scaledSize: new this.props.google.maps.Size(48, 48)
          }}
        />
      </Map>
    );
  }
}

export default GoogleApiWrapper({
  apiKey: "AIzaSyDY56BC6_9IRY8xTw7n5p1CKQhpbUT5eJU"
})(CustomMap);

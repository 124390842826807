import React, {useContext, useEffect, useState} from "react";
import styled from "styled-components";
import { CartContext } from "../context/CartContext";
import { Link } from "react-router-dom";
import CartLineItem from "./CartLineItem";
import { stringifyPrice } from "../../utils";
import CheckoutButton from "./CheckoutButton";
import ReactPixel from "react-facebook-pixel";

const StyledWrapper = styled.div`
  background-color: #3a383a;
  min-height: 100vh;
  padding-top: 150px;
  padding-bottom: 150px;

  .btn-golden {
    width: 240px;
  }

  h1,
  h2 {
    color: #f5e5c4;
    font-size: 40px;
    font-weight: 400;
  }

  h2 {
    font-size: 32px;
  }

  .subtotal {
    margin: 10px 0px;
    text-align: right;

    p {
      color: #f5e5c4;
      font-size: 18px;
      font-weight: 600;
      letter-spacing: 0.23px;
      text-transform: uppercase;

      span {
        color: #ffffff;
        font-size: 20px;
        font-weight: 600;
      }
    }
  }

  #sidebar {
    p {
      color: #f5e5c4;
      font-size: 12px;
      font-weight: 400;
      letter-spacing: 0.15px;
      margin-bottom: 0px;
    }
  }

  .order-notes {
    height: 100px;
    width: 235px;
    border: 1px solid #c3915b;
    background:transparent;
    color:white;
    padding:0 5px;
  }

  .order-notes:focus{
    outline: none !important;
  }

  .form-check{
    padding-left:2.25em;
  }

  .text-box{

  }

  textarea::placeholder {
    color: #c3915b;
    opacity: 50%;
    padding-left:1px;
  }

  input::placeholder{
    color: #c3915b;
    opacity:50%;
    padding-left:1px;
  }

  .order-from{
    width:235px;
  }

  input[type=text]:focus{
    border-top:0px;
    border-bottom: 1px solid #c3915b;
    border-left: 1px solid #c3915b;
    border-right: 1px solid #c3915b;
  }

  .name-field{
    width:235px;
    background:transparent;
    border-top:0px;
    border-bottom: 1px solid #c3915b;
    border-left: 1px solid #c3915b;
    border-right: 1px solid #c3915b;
    background:transparent;
    color:white;
    font-size:16px;
    padding:0 5px;
  }

  .checkbox {
    width: 100%;
    margin: 15px auto;
    position: relative;
    display: block;
}

.checkbox input[type="checkbox"] {
    width: auto;
    opacity: 0.00000001;
    position: absolute;
    left: 0;
    margin-left: -20px;
}
.checkbox label {
    position: relative;
}
.checkbox label:before {
    content: '';
    position: absolute;
    left: 0;
    top: -3px;
    margin: 4px;
    width: 22px;
    height: 22px;
    transition: transform 0.28s ease;
    border-radius: 0px;
    border: 1px solid #c3915b;
}
.checkbox label:after {
  content: '';
    display: block;
    width: 13px;
    height: 7px;
    border-bottom: 3px solid #fff;
    border-left: 3px solid #fff;
    -webkit-transform: rotate(-45deg) scale(0);
    transform: rotate(-45deg) scale(0);
    transition: transform ease 0.25s;
    will-change: transform;
    position: absolute;
    top: 8px;
    left: 9px;
}
.checkbox input[type="checkbox"]:checked ~ label::before {
    background:#c3915b;
}

.checkbox input[type="checkbox"]:checked ~ label::after {
    -webkit-transform: rotate(-45deg) scale(1);
    transform: rotate(-45deg) scale(1);
}

.checkbox label {
    display: block;
    padding-left: 40px;
    margin-bottom: 0;
    font-weight: normal;
    cursor: pointer;
    vertical-align: sub;
}
.checkbox label span {
    top: 50%;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
}
.checkbox input[type="checkbox"]:focus + label::before {
    outline: 0;
}

.gift-div{
  padding-left:70px;
  @media(max-width:600px){
    padding-left:150px;
  }
}

.gift-msg-box{
  padding-left:55px;
  @media(max-width:600px){
    padding-left:78px;
  }
}

.order-from:focus{
  outline: none !important;
}

.box-width{
  width:240px;
  text-align: left;
}
  
.note-titles {
    font-size: 14px;
    color: #f5e5c4;
    padding-bottom: 2px;
}
  
  h4.note-titles {
    font-size: 16px;
  }
`;

const Cart = () => {
  const { items, subtotal } = useContext(CartContext);
  const [OrderNotes, setOrderNotes] = useState("");
  const [orderFrom,setOrderFrom] = useState("");

  useEffect( () => {
    ReactPixel.pageView();
  })

  return (
    <StyledWrapper>
      {Object.keys(items).length === 0 && (
        <div className="container py-5">
          <div className="row">
            <div className="col-12 text-center">
              <h2>You have no items in your cart yet</h2>
              <Link to="/shop">
                <button className="btn btn-golden my-4">VISIT THE SHOP</button>
              </Link>
            </div>
          </div>
        </div>
      )}
      {Object.keys(items).length > 0 && (
        <div className="container">
          <div className="row">
            <div className="title col-12">
              <h1 className="mb-0">
                Cart ({Object.keys(items).length} {Object.keys(items).length === 1 ? "item" : "items"})
              </h1>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-8 py-5">
              {Object.values(items).map((item, key) => (
                <CartLineItem
                  first={key === 0 ? true : false}
                  key={key}
                  item={item}
                />
              ))}
              <div className="subtotal">
                <p className="mb-0">
                  SUBTOTAL:{" "}
                  <span className="ml-3">{stringifyPrice(subtotal)}</span>
                </p>
                <p style={{fontSize:"12px",fontWeight:"400",textTransform:"none"}}>Delivery cost will be calculated during checkout.</p>
              </div>
            </div>
            <div id="sidebar" className="col-lg-4" style={{ zIndex: 0 }}>
              <div className="mx-3 text-center d-flex flex-column align-items-start">
                <div className="sticky-top pt-5 pb-0">
                  <CheckoutButton OrderNotes={OrderNotes} from={orderFrom}/>
                </div>
                <div>
                    <div className="d-flex mt-3">
                        <h4 className="note-titles box-width">Enter a message to go along with your cake</h4>
                    </div>
                    <div className="d-flex">
                        <div className="note-titles mr-auto">Max Characters: 100</div>
                    </div>
                    <div className="d-flex">
                        <div className="text-box">
                    <textarea className="order-notes d-block" type="text" value={OrderNotes}
                              placeholder="Message" maxLength="100"
                              onChange={(e) => setOrderNotes(e.target.value)} />
                            <div className="order-from">
                                <input type="text" placeholder="FROM:" className="name-field" onChange={e => setOrderFrom(e.target.value)}/>
                            </div>
                        </div>
                    </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </StyledWrapper>
  );
};

export default Cart;

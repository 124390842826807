import React, { useState, useContext, useEffect } from "react";
import { useQuery } from "@apollo/react-hooks";
import VariantBox from "./VariantBox";
import { GET_PRODUCT_DETAILS, stringifyPrice } from "../../utils";
import Signature from "../../assets/images/signature_horizontal.png";
import { LoadingContext } from "../context/LoadingContext";
import { CartContext } from "../context/CartContext";
import { TransitionContext } from "../context/TransitionContext";
import Carousel from "../Carousel/";
import StyledWrapper from "./style";
import Wine from "../../assets/images/Asset 10.svg";
import Cheese from "../../assets/images/Asset 11.svg";
import Beverages from "../../assets/images/Asset 8.svg";
import Food from "../../assets/images/Asset 9.svg";
import { Modal, ModalBody } from "reactstrap";
import ReactHtmlParser from "react-html-parser";
import { toast } from "react-toastify";
import { Link } from "react-router-dom";
import CustomRelatedProducts from "../RelatedProducts/CustomRelatedProducts";
import NotFound from "../NotFound";
import ReactGA from "react-ga";
import Ripples from "react-ripples";
import ReactPixel from "react-facebook-pixel";
import { Helmet } from "react-helmet";
import CalenderIcon from "../../assets/images/calendar.png";
import DatePicker, { CalendarContainer } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import QuantityEditor from "../Cart/QuantityEditor";
import { addDays } from "date-fns";
import eggimage from "../../assets/egg.svg";
import peanutimage from "../../assets/peanuts.svg";
import wheatimage from "../../assets/wheat.svg";
import milkimage from "../../assets/milk.svg";
import glutenFree from "../../assets/glutenFree.svg";
import local from "../../assets/local.svg";
import pure from "../../assets/pure.svg";

const LOCATION_URL = window.location.href;

toast.configure();

const ProductDetail = ({
  match: {
    params: { handle },
  },
}) => {
  const [selectedVariant, setSelectedVariant] = useState({
    id: "",
    sku: "",
    parentProductTitle: "",
    handle: "",
    title: "",
    price: "",
    image: "",
    deliveryDate: "",
  });

  useEffect(() => {
    if (selectedVariant.id !== "") {
      setTimeout(() => {
        ReactPixel.pageView();
        ReactPixel.track("ViewContent", {
          content_ids: selectedVariant.sku,
          content_name: selectedVariant.parentProductTitle,
          content_type: "product",
          currency: "INR",
          value: selectedVariant.price,
        });
      }, 0);
    }
  }, [selectedVariant]);

  const { showLoading, hideLoading } = useContext(LoadingContext);
  const { cartDispatch } = useContext(CartContext);
  const transitionContext = useContext(TransitionContext);
  const {
    showingTransition,
    transitionCompleted,
    moveImageToCarouselPosition,
    setHideTransition,
  } = transitionContext;
  const [loadingFromList, setLoadingFromList] = useState(
    showingTransition === true ? true : undefined
  );
  const [movingStarted, setMovingStarted] = useState(false);

  const [showSavorModal, setShowSavorModal] = useState(false);
  const [showIngredientsModal, setShowIngredientsModal] = useState(false);
  const [deliveryDate, setDeliveryDate] = useState(null);
  const [openCalender, setOpenCalender] = useState(false);
  const [itemQuantity, setItemQuantity] = useState(1);
  const [timeSlot, setTimeSlot] = useState("Afternoon");

  useEffect(() => {
    if (!loadingFromList) {
      showLoading();
    }
  }, [showLoading, loadingFromList]);

  const notify = () =>
    toast("Item added to cart", {
      position: toast.POSITION.BOTTOM_RIGHT,
      className: "toast-bronze-background",
      progressClassName: "toast-progress-bar",
      bodyClassName: "toast-body",
    });

  const completeTransition = () => {
    if (loadingFromList === undefined) {
      setLoadingFromList(false);
      hideLoading();
      return;
    }
    if (!movingStarted && showingTransition && !transitionCompleted) {
      setMovingStarted(true);
    }
  };

  useEffect(() => {
    if (movingStarted && showingTransition && !transitionCompleted) {
      const getImageDimensions = () => {
        const lastImage = document.getElementsByClassName(
          "alice-carousel__stage-item __active"
        )[0].children[0];
        const rect = lastImage.getBoundingClientRect();
        /*
         *So the weird animation occurs on all products except the first.
         *For these, for some reason rect.y returns 0 initially and then returns ~825
         *I assume that the Carousel isn't loading on time perhaps
         */
        return new DOMRect(rect.x, rect.y, rect.width, rect.width * 1.501818);
      };
      let rect = getImageDimensions();
      setTimeout(() => {
        moveImageToCarouselPosition({
          ...transitionContext,
          endX: rect.x,
          endY: rect.y - 72,
          endWidth: rect.width,
          endHeight: rect.height,
        });
      }, 0);
      setTimeout(() => {
        setHideTransition(transitionContext);
        setMovingStarted(false);
      }, 1000);
    }
  }, [
    moveImageToCarouselPosition,
    movingStarted,
    setHideTransition,
    showingTransition,
    transitionCompleted,
    transitionContext,
  ]);

  const { loading, error, data } = useQuery(GET_PRODUCT_DETAILS, {
    variables: { handle },
    onCompleted: (data) => {
      if (data.productByHandle !== null) {
        const { price, title } = data.productByHandle.variants.edges[0].node;
        setSelectedVariant({
          id: data.productByHandle.variants.edges[0].node.id,
          sku: data.productByHandle.variants.edges[0].node.sku,
          parentProductTitle: data.productByHandle.title,
          handle: handle,
          title: title,
          price: price,
          image: data.productByHandle.images.edges[0].node.transformedSrc,
        });
      }
    },
  });

  if (loading) return null;

  if (error) {
    return `Error! ${error}`;
  }

  if (data.productByHandle === null) {
    return <NotFound />;
  }

  if (data.productByHandle !== null) {
    const {
      productType,
      title,
      descriptionHtml,
      images,
      variants: { edges: variants },
    } = data.productByHandle;

    setTimeout(() => {
      completeTransition();
    }, 0);

    let imgArray = [];
    images.edges.forEach((edge) => {
      imgArray.push(edge.node.transformedSrc);
    });

    // PROCESSING METAFIELDS
    let metafields = {};

    let dynamicMetafieldsArray = [];
    let rightSideContentTitle = "";
    let rightSideContentValue = "";
    let leftSideContentTitle = "";
    let leftSideContentValue = "";
    const rightSideMetafield = "rightSideContent";
    const leftSideMetafield = "leftSideContentMuted";
    let tagsToDisplay;
    data.productByHandle.metafields.edges.forEach((field) => {
      metafields[`${field.node.key}`] = field.node.value;

      tagsToDisplay = metafields.leftSideTags && metafields.leftSideTags;
      if (
        productType === "Dynamic" &&
        !field.node.key.includes("contains") &&
        !field.node.key.includes("flavor") &&
        !field.node.key.includes(rightSideMetafield) &&
        !field.node.key.includes(leftSideMetafield)
      )
        dynamicMetafieldsArray.push({
          key: field.node.key.toLowerCase(),
          values: field.node.value,
        });

      // Prepare the Right Side Content
      if (field.node.key === rightSideMetafield) {
        rightSideContentTitle = field.node.value.split(";")[0];
        rightSideContentValue = field.node.value.split(";")[1];
      }
      // Prepare the Left Side Content
      if (field.node.key === leftSideMetafield) {
        leftSideContentTitle = field.node.value.split(";")[0];
        leftSideContentValue = field.node.value.split(";")[1];
      }

      //   Filter all Metafields and retrieve FIELDS TO DISPLAY.
      if (tagsToDisplay) {
        dynamicMetafieldsArray = dynamicMetafieldsArray.filter((metafield) =>
          tagsToDisplay.includes(metafield.key.split("_")[1])
        );
        tagsToDisplay = tagsToDisplay.split(",");
      }
    });

    let flavors = [];
    if (metafields.flavors) {
      flavors = metafields.flavors.split(",");
    }

    let contentsArray = [];
    let contentsJSON = {};
    if (metafields.contents) {
      contentsJSON = JSON.parse(metafields.contents);
      contentsArray = Object.values(contentsJSON).map((value) => ({
        image: value.image,
        text: value.text,
      }));
    }

    const handleCalenderOpen = (val) => {
      setOpenCalender(!val);
    };

    const dateFunction = (val) => {
      if (!val) return null;
      var days = [
        "Sunday",
        "Monday",
        "Tuesday",
        "Wednesday",
        "Thursday",
        "Friday",
        "Saturday",
      ];
      let arr = [];
      let dateString = val.toDateString();
      if (val.getDay() === 0) return null;
      let obj1 = dateString.split(" ").slice(1, 3);
      arr.push(days[val.getDay()] + ",");
      arr.push(...obj1);
      return arr.join(" ");
    };

    const MyContainer = ({ className, children }) => {
      return (
        <div>
          <CalendarContainer className={className}>
            <div style={{ position: "relative" }}>{children}</div>
          </CalendarContainer>
          <div className="container-fluid time-slot-container">
            <div className="col-6 px-0" style={{ marginLeft: "52px" }}>
              <div className="label-container">
                <input
                  className="form-check-input"
                  type="radio"
                  checked={timeSlot === "Afternoon"}
                  onChange={(e) => setTimeSlot(e.target.value)}
                  value="Afternoon"
                />
                <div
                  className="justify-content-center"
                  onClick={() => setTimeSlot("Afternoon")}
                >
                  <div className="day-time">Afternoon</div>
                  <div className="time-day">12pm-4pm</div>
                </div>
              </div>
            </div>
            <div className="col-6 px-0">
              <div className="label-container">
                <input
                  className="form-check-input"
                  type="radio"
                  checked={timeSlot === "Evening"}
                  onChange={(e) => setTimeSlot(e.target.value)}
                  value="Evening"
                />
                <div
                  className="justify-content-center"
                  onClick={() => setTimeSlot("Evening")}
                >
                  <div className="day-time">Evening</div>
                  <div className="time-day">4pm-8pm</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    };

    return (
      <StyledWrapper>
        {!loading && (
          <div className="container" style={{ maxWidth: 1440 }}>
            <div className="row">
              <div className="col-lg-7 text-right">
                <Carousel images={imgArray.reverse()} />
              </div>
              <div className="col-lg-5 mt-5 mt-lg-0">
                <h2>{title}</h2>
                <h2 className="subtitle">{metafields.subtitle}</h2>

                {productType === "Collections" && (
                  <div className="collection-contents my-4">
                    {contentsArray.map((item) => (
                      <div className="d-flex align-items-center my-3 collection-icon-holder">
                        <img
                          className="img-fluid"
                          src={item.image}
                          alt=""
                          style={{ width: 50, minWidth: 50, maxWidth: 50 }}
                        />
                        <span className="ml-3">{item.text}</span>
                      </div>
                    ))}
                  </div>
                )}

                {productType !== "Collections" && !metafields.hideIngredients && (
                  <span
                    className="d-block modal-launch mt-4"
                    onClick={() => setShowIngredientsModal(true)}
                  >
                    INGREDIENTS
                  </span>
                )}

                {variants.length > 1 && (
                  <div id="variantSelector" className="my-5">
                    {variants.map((variant) => (
                      <VariantBox
                        key={variant.node.id}
                        variant={variant.node}
                        selected={
                          selectedVariant &&
                          selectedVariant.id === variant.node.id
                        }
                        onClick={() => {
                          setSelectedVariant({
                            id: variant.node.id,
                            price: variant.node.price,
                            title: variant.node.title,
                            handle: handle,
                            parentProductTitle: title,
                            sku: variant.node.sku,
                            image: images.edges[0].node.transformedSrc,
                            deliveryDate: deliveryDate,
                          });
                        }}
                      />
                    ))}
                  </div>
                )}
                <div className="price my-4">
                  {selectedVariant &&
                    stringifyPrice(selectedVariant.price * 1.18)}
                  <span className="gst-disclaimer d-block">(incl. of GST)</span>
                </div>

                {selectedVariant.id !== "" && (
                  <div>
                    <Helmet>
                      <title>{selectedVariant.parentProductTitle}</title>
                      <meta property="og:title" content={title} />
                      <meta
                        property="og:description"
                        content={metafields.subtitle}
                      />
                      <meta
                        property="og:image"
                        content={selectedVariant.image}
                      />
                      <meta property="og:url" content={LOCATION_URL} />
                      <meta
                        property="product:retailer_item_id"
                        content={selectedVariant.sku}
                      />
                      <meta
                        property="product:brand"
                        content="Ether Chocolate"
                      />
                      <meta
                        property="product:availability"
                        content="in stock"
                      />
                      <meta property="product:condition" content="new" />
                      <meta
                        property="product:price:amount"
                        content={selectedVariant.price}
                      />
                      <meta property="product:price:currency" content="INR" />
                    </Helmet>
                    <div itemScope itemType="http://schema.org/Product">
                      <meta itemProp="brand" content="Ether Chocolate" />
                      <meta itemProp="name" content={title} />
                      <meta
                        itemProp="description"
                        content={metafields.subtitle}
                      />
                      <meta
                        itemProp="productID"
                        content={selectedVariant.sku}
                      />
                      <meta itemProp="url" content={LOCATION_URL} />
                      <meta itemProp="image" content={selectedVariant.image} />
                      <div
                        itemProp="offers"
                        itemScope
                        itemType="http://schema.org/Offer"
                      />
                      <link
                        itemProp="availability"
                        href="http://schema.org/InStock"
                      />
                      <link
                        itemProp="itemCondition"
                        href="http://schema.org/NewCondition"
                      />
                      <meta itemProp="price" content={selectedVariant.price} />
                      <meta itemProp="priceCurrency" content="INR" />
                    </div>
                  </div>
                )}

                {metafields.allowPreorder !== "true" &&
                  metafields.contactToOrder === "true" && (
                    <>
                      <Link
                        to="/contact"
                        className="btn btn-golden mb-3"
                        id="contactToOrder"
                      >
                        Contact Us To Order
                      </Link>
                    </>
                  )}

                {metafields.allowPreorder !== "true" &&
                  metafields.contactToOrder !== "true" &&
                  metafields.outOfStock !== "true" && (
                    <>
                      <Ripples>
                        <button
                          className="btn btn-golden mb-3"
                          onClick={() => {
                            notify();
                            cartDispatch({
                              type: "ADD_ITEM",
                              payload: { ...selectedVariant, quantity: 1 },
                            });
                            ReactGA.event({
                              category: "User Button Click",
                              action: "Added Item To Cart",
                              label: `${title} - ${selectedVariant.title}`,
                            });
                            ReactPixel.track("AddToCart", {
                              content_ids: selectedVariant.sku,
                              content_name: selectedVariant.parentProductTitle,
                              content_type: "product",
                              currency: "INR",
                              value: selectedVariant.price,
                            });
                          }}
                        >
                          Add To Cart
                        </button>
                      </Ripples>
                      {data.productByHandle.handle ===
                        "cavities-001-the-issue-with-cake" && (
                        <Ripples>
                          <button className="btn btn-golden mb-3">
                            <a
                              href={metafields.attachments}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              <p className="mb-0"> DIGITAL PDF </p>
                              <span> Free DOWNLOAD </span>
                            </a>
                          </button>
                        </Ripples>
                      )}
                    </>
                  )}

                {metafields.outOfStock === "true" && (
                  <p className="out-of-stock">
                    This product is currently out of stock.
                  </p>
                )}

                {metafields.allowPreorder === "true" &&
                  metafields.outOfStock !== "true" && (
                    <div>
                      <div className="container-fluid my-4 px-0">
                        <div className="row">
                          <div
                            className="col-2"
                            onClick={() => handleCalenderOpen(false)}
                            style={{ cursor: "pointer" }}
                          >
                            <img
                              src={CalenderIcon}
                              className="img-fluid calender-icon"
                              alt="calender"
                            />
                          </div>
                          <div
                            className="col-10 d-flex padding-date-box flex-column px-0 justify-content-center"
                            style={{ cursor: "pointer" }}
                            onClick={() => handleCalenderOpen(false)}
                          >
                            <div className="calender-title">
                              SELECT DELIVERY DATE & TIME
                            </div>
                            {deliveryDate === null ? (
                              <div
                                className="picked-date"
                                style={{ opacity: "50%" }}
                              >
                                DD/MM/YYYY | Time
                              </div>
                            ) : (
                              <div className="picked-date">
                                {dateFunction(deliveryDate)} |{" "}
                                {timeSlot === "Evening"
                                  ? "4pm-8pm"
                                  : "12pm-4pm"}
                              </div>
                            )}
                          </div>
                        </div>
                        <div className="calender-under-text pt-3">
                          Please{" "}
                          <Link to="/contact">
                            <strong>contact us</strong>
                          </Link>{" "}
                          for more information on delivery
                        </div>
                        <DatePicker
                          calendarClassName="width-cal"
                          showPopperArrow={false}
                          selected={deliveryDate}
                          customInput={<></>}
                          minDate={
                            new Date().getHours() >= 17
                              ? addDays(new Date(), 2)
                              : addDays(new Date(), 1)
                          }
                          onClickOutside={() => setOpenCalender(false)}
                          onChange={(date) => {
                            setDeliveryDate(date);
                          }}
                          open={openCalender}
                          calendarContainer={MyContainer}
                        />
                      </div>
                      <div className="container">
                        <div className="row">
                          <div className="col-4 px-0 mb-3 align-content">
                            <QuantityEditor
                              quantity={itemQuantity}
                              onDecrement={() => {
                                setItemQuantity(
                                  itemQuantity > 1 ? itemQuantity - 1 : 1
                                );
                              }}
                              onIncrement={() => {
                                setItemQuantity(itemQuantity + 1);
                              }}
                            />
                          </div>
                          <div className="col-8 px-sm-2">
                            <button
                              className="btn add-cart-btn mb-3"
                              disabled={deliveryDate === null}
                              onClick={() => {
                                notify();
                                cartDispatch({
                                  type: "ADD_ITEM",
                                  payload: {
                                    ...selectedVariant,
                                    quantity: itemQuantity,
                                    deliveryDate: deliveryDate,
                                    timeSlot: timeSlot,
                                  },
                                });
                                ReactGA.event({
                                  category: "User Button Click",
                                  action: "Added Item To Cart",
                                  label: `${title} - ${selectedVariant.title}`,
                                });
                                ReactPixel.track("AddToCart", {
                                  content_ids: selectedVariant.sku,
                                  content_name:
                                    selectedVariant.parentProductTitle,
                                  content_type: "product",
                                  currency: "INR",
                                  value: selectedVariant.price,
                                });
                              }}
                            >
                              ADD TO BAG
                            </button>
                          </div>
                        </div>
                        <div className="row">
                          <div className="shipping">
                            <p className="mb-4">Mumbai deliveries only</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}

                <div className="shipping">
                  {metafields.shippingDetails === undefined &&
                  productType !== "Cakes" ? (
                    <p>
                      {" "}
                      {metafields.weight && (
                        <span>
                          {metafields.weight} {" | "}
                        </span>
                      )}
                      Now Shipping Pan India
                    </p>
                  ) : (
                    <p> {metafields.shippingDetails} </p>
                  )}
                </div>
                <div
                  className={`description ${metafields.weight && "mt-5 pt-5"}`}
                >
                  {ReactHtmlParser(descriptionHtml)}
                </div>
                <div className="signature my-4 row">
                  <div className="col-lg-6">
                    <img
                      className="img-fluid d-block my-3"
                      src={Signature}
                      alt=""
                    />
                    <span>Chef Prateek Bakhtiani</span>
                  </div>
                  <div className="col-lg-6 d-lg-flex justify-content-lg-end align-items-lg-end mt-4 mt-lg-0">
                    {productType !== "Cakes" &&
                      productType !== "Dynamic" &&
                      data.productByHandle.title !== "Dominicana" &&
                      data.productByHandle.handle !==
                        "cavities-001-the-issue-with-cake" && (
                        <span
                          className="modal-launch"
                          onClick={() => setShowSavorModal(true)}
                        >
                          HOW TO STORE/SAVOR
                        </span>
                      )}
                  </div>
                </div>
              </div>
            </div>
            {productType === "Collections" && flavors.length > 0 && (
              <div className="row extra-info-collection">
                <div
                  className="col-lg-7 text-right flavor-box p-5"
                  style={{
                    backgroundImage: `url(${metafields.flavorPhotoLeft})`,
                    backgroundRepeat: "norepeat",
                    backgroundSize: "cover",
                  }}
                >
                  <h3>FLAVORS</h3>
                  {flavors.map((flavor) => (
                    <p>{flavor}</p>
                  ))}
                </div>
                <div
                  className="col-lg-5"
                  style={{
                    backgroundImage: `url(${metafields.flavorPhotoRight})`,
                    backgroundRepeat: "norepeat",
                    backgroundSize: "cover",
                    minHeight: 360,
                  }}
                >
                  <div
                    className="gradient"
                    style={{
                      position: "absolute",
                      top: 0,
                      left: 0,
                      width: "100%",
                      height: "100%",
                      backgroundImage:
                        "linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7))",
                    }}
                  ></div>
                </div>
              </div>
            )}
            {productType !== "Collections" &&
              productType !== "Cakes" &&
              (metafields.food ||
                metafields.cheese ||
                metafields.wine ||
                metafields.beverages) &&
              metafields.shouldHideDynamicContent !== "true" && (
                <div className="row extra-info">
                  <div
                    className="col-lg-7 p-5"
                    style={{
                      backgroundColor: "#272626",
                    }}
                  >
                    <div className="row">
                      <div className="col-12">
                        <h3 className="mb-3">PAIRING SUGGESTIONS</h3>
                      </div>
                      {metafields.food && (
                        <div className="pairing-col col-12 col-md-6 my-3">
                          <img
                            src={Food}
                            alt=""
                            className="food-pairing-icon img-fluid"
                          />
                          <div>
                            <h4>FOOD</h4>
                            {metafields.food.split(",").map((item) => (
                              <p>{item}</p>
                            ))}
                          </div>
                        </div>
                      )}
                      {metafields.cheese && (
                        <div className="pairing-col col-12 col-md-6 my-3">
                          <img
                            src={Cheese}
                            alt=""
                            className="food-pairing-icon img-fluid"
                          />
                          <div>
                            <h4>CHEESE</h4>
                            {metafields.cheese.split(",").map((item) => (
                              <p>{item}</p>
                            ))}
                          </div>
                        </div>
                      )}
                      {metafields.wine && (
                        <div className="pairing-col col-12 col-md-6 my-3">
                          <img
                            src={Wine}
                            alt=""
                            className="food-pairing-icon img-fluid"
                            style={{ height: 32 }}
                          />
                          <div>
                            <h4>WINE</h4>
                            {metafields.wine.split(",").map((item) => (
                              <p>{item}</p>
                            ))}
                          </div>
                        </div>
                      )}
                      {metafields.beverages && (
                        <div className="pairing-col col-12 col-md-6 my-3">
                          <img
                            src={Beverages}
                            alt=""
                            className="food-pairing-icon img-fluid"
                          />
                          <div>
                            <h4>BEVERAGES</h4>
                            {metafields.beverages.split(",").map((item) => (
                              <p>{item}</p>
                            ))}
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                  <div
                    className="col-lg-5"
                    style={{
                      backgroundImage: `url(${metafields.flavorPhotoRight})`,
                      backgroundRepeat: "norepeat",
                      backgroundSize: "cover",
                      minHeight: 360,
                    }}
                  >
                    <div
                      className="tasting-box p-5"
                      style={{
                        position: "absolute",
                        top: 0,
                        left: 0,
                        width: "100%",
                        height: "100%",
                        backgroundImage:
                          "linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7))",
                      }}
                    >
                      <h3>TASTING NOTES</h3>
                      {metafields.tastingNotes &&
                        metafields.tastingNotes
                          .split(",")
                          .map((item) => <p>{item}</p>)}
                    </div>
                  </div>
                </div>
              )}

            {productType === "Cakes" &&
              metafields.shouldHideDynamicContent !== "true" && (
                <div className="row extra-info">
                  <div
                    className="col-lg-7"
                    style={{
                      backgroundColor: "#272626",
                      display: "flex",
                    }}
                  >
                    <div className="col-4 chocolate-tasting-box py-5">
                      <div>
                        <h3>TASTING NOTES</h3>
                        {metafields.tastingNotes &&
                          metafields.tastingNotes
                            .split(",")
                            .map((item) => <p>{item}</p>)}
                      </div>
                      <br />
                      <div>
                        <div className="second-div">With undertones of</div>
                        {metafields.underTones &&
                          metafields.underTones
                            .split(",")
                            .map((item) => (
                              <p className="light-text">{item}</p>
                            ))}
                      </div>
                    </div>
                    <div className="col-4 chocolate-tasting-box py-5">
                      <div>
                        <h3>SIZE</h3>
                        <p>{metafields.size}</p>
                      </div>
                    </div>
                    <div className="col-4 chocolate-tasting-box py-5">
                      <div>
                        <h3>CONSUME</h3>
                        <p>{metafields.consume}</p>
                      </div>
                    </div>
                  </div>
                  <div
                    className="col-lg-5"
                    style={{
                      backgroundImage: `url(${metafields.flavorPhotoRight})`,
                      backgroundRepeat: "norepeat",
                      backgroundSize: "cover",
                      minHeight: 360,
                    }}
                  >
                    <div
                      className="chocolate-tasting-box p-5"
                      style={{
                        position: "absolute",
                        display: "flex",
                        justifyContent: "space-between",
                        flexDirection: "column",
                        top: 0,
                        left: 0,
                        width: "100%",
                        height: "100%",
                        backgroundImage:
                          "linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7))",
                      }}
                    >
                      <div>
                        <h3>ALLERGENS</h3>
                        {metafields.allergens &&
                          metafields.allergens
                            .split("\n")
                            .map((item) => <p>{ReactHtmlParser(item)}</p>)}
                      </div>
                      <div className="d-flex justify-content-around">
                        {metafields.containsEgg === "true" && (
                          <div className="flex-column text-center">
                            <img
                              className="item-images"
                              src={eggimage}
                              alt=""
                            />
                            <div className="image-txt">Eggs</div>
                          </div>
                        )}
                        {metafields.containsGluten === "true" && (
                          <div className="flex-column text-center">
                            <img
                              className="item-images"
                              src={wheatimage}
                              alt=""
                            />
                            <div className="image-txt">Gluten</div>
                          </div>
                        )}
                        {metafields.containsNuts === "true" && (
                          <div className="flex-column text-center">
                            <img
                              className="item-images"
                              src={peanutimage}
                              alt=""
                            />
                            <div className="image-txt">Nuts</div>
                          </div>
                        )}
                        {metafields.containsDairy === "true" && (
                          <div className="flex-column text-center">
                            <img
                              className="item-images"
                              src={milkimage}
                              alt=""
                            />
                            <div className="image-txt">Dairy</div>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              )}

            {productType === "Dynamic" &&
              metafields.shouldHideDynamicContent !== "true" && (
                <div className="row extra-info">
                  <div
                    className="col-lg-7"
                    style={{
                      backgroundColor: "#272626",
                      display: "flex",
                    }}
                  >
                    <div className="col-12 d-flex">
                      <div className="row">
                        {dynamicMetafieldsArray.map(
                          ({ key, values }, index) => (
                            <div className="col-4 chocolate-tasting-box py-5">
                              <div>
                                <h3>{key.split("_")[1]}</h3>
                                {values &&
                                  values
                                    .split(",")
                                    .map((item) => (
                                      <p className="text-capitalize">{item}</p>
                                    ))}
                              </div>
                              {leftSideContentTitle && index === 0 && (
                                <>
                                  <br />
                                  <div>
                                    <div className="second-div text-uppercase">
                                      {leftSideContentTitle}
                                    </div>
                                    {leftSideContentValue &&
                                      leftSideContentValue
                                        .split(",")
                                        .map((item) => (
                                          <p className="light-text text-capitalize">
                                            {item}
                                          </p>
                                        ))}
                                  </div>
                                </>
                              )}
                            </div>
                          )
                        )}
                      </div>
                    </div>
                  </div>
                  <div
                    className="col-lg-5"
                    style={{
                      backgroundImage: `url(${metafields.flavorPhotoRight})`,
                      backgroundRepeat: "norepeat",
                      backgroundSize: "cover",
                      minHeight: 360,
                    }}
                  >
                    <div
                      className="chocolate-tasting-box p-5"
                      style={{
                        position: "absolute",
                        display: "flex",
                        justifyContent: "space-between",
                        flexDirection: "column",
                        top: 0,
                        left: 0,
                        width: "100%",
                        height: "100%",
                        backgroundImage:
                          "linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7))",
                      }}
                    >
                      <div>
                        <h3>{rightSideContentTitle}</h3>
                        {rightSideContentValue &&
                          rightSideContentValue
                            .split(",")
                            .map((item) => <p>{ReactHtmlParser(item)}</p>)}
                      </div>
                      <div className="d-flex justify-content-around mt-5 mt-lg-0">
                        {metafields.isPure === "true" && (
                          <div className="flex-column text-center">
                            <img className="item-images" src={pure} alt="" />
                            <div className="image-txt">Pure</div>
                          </div>
                        )}
                        {metafields.containsEgg === "true" && (
                          <div className="flex-column text-center">
                            <img
                              className="item-images"
                              src={eggimage}
                              alt=""
                            />
                            <div className="image-txt">Eggs</div>
                          </div>
                        )}
                        {metafields.containsGluten === "true" && (
                          <div className="flex-column text-center">
                            <img
                              className="item-images"
                              src={wheatimage}
                              alt=""
                            />
                            <div className="image-txt">Gluten</div>
                          </div>
                        )}
                        {metafields.containsGluten !== "true" && (
                          <div className="flex-column text-center">
                            <img
                              className="item-images"
                              src={glutenFree}
                              alt=""
                            />
                            <div className="image-txt">Gluten Free</div>
                          </div>
                        )}
                        {metafields.isLocal === "true" && (
                          <div className="flex-column text-center">
                            <img className="item-images" src={local} alt="" />
                            <div className="image-txt">Local</div>
                          </div>
                        )}
                        {metafields.containsNuts === "true" && (
                          <div className="flex-column text-center">
                            <img
                              className="item-images"
                              src={peanutimage}
                              alt=""
                            />
                            <div className="image-txt">Nuts</div>
                          </div>
                        )}
                        {metafields.containsDairy === "true" && (
                          <div className="flex-column text-center">
                            <img
                              className="item-images"
                              src={milkimage}
                              alt=""
                            />
                            <div className="image-txt">Dairy</div>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              )}

            {productType === "Preserves" && (
              <div className="row mt-5">
                <div
                  className="col-lg-7 p-5"
                  style={{
                    backgroundImage: `url(${metafields.recipePhotoLeft})`,
                    backgroundRepeat: "norepeat",
                    backgroundSize: "cover",
                    minHeight: 360,
                  }}
                >
                  <div
                    className="tasting-box p-5"
                    style={{
                      position: "absolute",
                      top: 0,
                      left: 0,
                      width: "100%",
                      height: "100%",
                      backgroundImage:
                        "linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7))",
                    }}
                  >
                    <div className="row">
                      <div className="col-12">
                        <h3 className="mb-3">RECIPES</h3>
                      </div>

                      {metafields.recipes &&
                        metafields.url &&
                        metafields.recipes.split(",").map((recipe, index) => (
                          <div className="col-12">
                            <a
                              style={{ fontSize: "18px" }}
                              href={metafields.url.split(",")[index]}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              <p
                                className="mb-2"
                                style={{
                                  color: "rgb(195, 145, 91)",
                                  fontSize: "18px",
                                }}
                              >
                                {" "}
                                {recipe}{" "}
                              </p>
                            </a>
                          </div>
                        ))}
                    </div>
                  </div>
                </div>
                <div
                  className="col-lg-5"
                  style={{
                    backgroundImage: `url(${metafields.flavorPhotoRight})`,
                    backgroundRepeat: "norepeat",
                    backgroundSize: "cover",
                    backgroundPosition: "center",
                    minHeight: 360,
                  }}
                >
                  <div
                    className="tasting-box p-5"
                    style={{
                      position: "absolute",
                      top: 0,
                      left: 0,
                      width: "100%",
                      height: "100%",
                      backgroundImage:
                        "linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7))",
                    }}
                  >
                    <h3>TASTING NOTES</h3>
                    {metafields.tastingNotes &&
                      metafields.tastingNotes
                        .split(",")
                        .map((item) => <p>{item}</p>)}
                  </div>
                </div>
              </div>
            )}

            {metafields.relatedProducts &&
              metafields.relatedProducts.length > 0 && (
                <CustomRelatedProducts
                  relatedHandles={metafields.relatedProducts.split(",")}
                />
              )}

            <Modal
              isOpen={showSavorModal}
              toggle={() => setShowSavorModal(!showSavorModal)}
              className="modal-container"
              centered={true}
            >
              <span
                className="close-btn"
                onClick={() => setShowSavorModal(false)}
              >
                &times;
              </span>
              <ModalBody>
                <h3 className="modal-launch mb-4">HOW TO STORE/SAVOR</h3>
                {ReactHtmlParser(metafields.storeSavor)}
              </ModalBody>
            </Modal>

            <Modal
              isOpen={showIngredientsModal}
              toggle={() => setShowIngredientsModal(!showIngredientsModal)}
              className="modal-container"
              centered={true}
            >
              <span
                className="close-btn"
                onClick={() => setShowIngredientsModal(false)}
              >
                &times;
              </span>
              <ModalBody>
                <h3 className="modal-launch mb-4">INGREDIENTS</h3>

                {metafields.ingredients ? (
                  ReactHtmlParser(metafields.ingredients)
                ) : (
                  <p>
                    This is a vegeterian, egg free product. It does contain
                    dairy and is produced in a facility with peanuts, and
                    tree-nuts.
                    <br />
                    <br />
                    If you would like a full list of Ingredients or would like
                    any further details on this collection please contact us. We
                    are happy to assist you.
                  </p>
                )}
              </ModalBody>
            </Modal>
          </div>
        )}
      </StyledWrapper>
    );
  }
};

export default ProductDetail;

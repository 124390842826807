import React, { useContext, useState } from "react";
import { Col, Container, Row } from "reactstrap";
import styled from "styled-components";
import AliceCarousel from "react-alice-carousel";

import ReactGA from "react-ga";
import ReactPixel from "react-facebook-pixel";

import Issue002 from "../../assets/images/cavities/issue002_front_cover.png";
import Banner from "../../assets/images/cavities/issue002_back_cover_1.png";
import Chef1 from "../../assets/images/cavities/Chef-2-1.png";
import Chef2 from "../../assets/images/cavities/Chef-2-2.png";
import Chef3 from "../../assets/images/cavities/Chef-2-3.png";
import Chef4 from "../../assets/images/cavities/Chef-2-4.png";
import Chef5 from "../../assets/images/cavities/Chef-2-5.png";
import Chef6 from "../../assets/images/cavities/Chef-2-6.png";
import Chef7 from "../../assets/images/cavities/Chef-2-7.png";
import { useQuery } from "@apollo/react-hooks";
import {
  cavities_handles,
  GET_PRODUCT_DETAILS,
  notifyAddedToCart,
} from "../../utils";
import { CartContext } from "../context/CartContext";

const StyledSection = styled.section`
  background: white;
  color: black;
  padding: 6rem 0 0;
  text-align: center;

  img {
    position: relative;
    &.mag {
      z-index: 5;
    }
    &.banner {
      width: 90%;
      margin: 0 auto;
      top: 10rem;
    }
  }

  h1 {
    color: #0b0b0b;
    font-size: 54px;
    font-weight: 700;
    margin: 5em 0 1em;
  }

  p,
  h4 {
    font-size: 24px;
  }

  button {
    font-size: 18px;
    font-weight: 600;
    line-height: 1.2em;
    font-family: "Volte", sans-serif;
    text-transform: uppercase;
    border: 1px solid transparent;
    outline: none;
    background: #0b0b0b;
    color: white;
    width: 230px;
    padding: 12px;
    &.outlined {
      background: transparent;
      color: #0b0b0b;
      border-color: #0b0b0b;
    }
  }

  .main-text {
    background-color: var(--yellow);
    position: relative;
    top: -8rem;
    .white-bg {
      width: 100%;
      height: 120px;
      position: absolute;
      inset: auto 0 0;
      background-color: white;
    }
  }

  .featured {
    padding: 15rem 0 0;
    h3 {
      font-size: 34px;
      font-weight: 700;
      letter-spacing: 0.27em;
      text-transform: uppercase;
    }
    .carousel-img {
      width: 446px;
      height: 620px;
    }
  }

  @media (max-width: 768px) {
    padding: 4rem 0 0;
    text-align: left;

    h1 {
      font-size: 20px;
      margin: 8.5em 0 1em;
    }
    h4 {
      font-size: 16px;
      text-align: center;
    }
    p,
    button {
      font-size: 14px;
    }
    img {
      &.banner {
        top: 5rem;
        width: 100%;
      }
    }
    .white-bg {
      display: none;
    }
    .featured {
      padding: 4rem 0 2rem;
      h3 {
        font-size: 18px;
        text-align: center;
      }
    }
  }
  @media (max-width: 576px) {
    .featured img.carousel-img {
      width: 100vw;
      height: 140vw;
    }
  }

  .add_to_cart_btn {
    :disabled {
      opacity: 0.5;
      cursor: not-allowed;
    }
  }
`;

const Issue2 = () => {
  const { cartDispatch } = useContext(CartContext);

  const [selectedVariant, setSelectedVariant] = useState({
    id: "",
    sku: "",
    parentProductTitle: "",
    handle: "",
    title: "",
    price: "",
    image: "",
    deliveryDate: "",
  });

  const imgs = [
    <img src={Chef1} alt="" className="carousel-img" />,
    <img src={Chef2} alt="" className="carousel-img" />,
    <img src={Chef3} alt="" className="carousel-img" />,
    <img src={Chef4} alt="" className="carousel-img" />,
    <img src={Chef5} alt="" className="carousel-img" />,
    <img src={Chef6} alt="" className="carousel-img" />,
    <img src={Chef7} alt="" className="carousel-img" />,
  ];

  const { loading, error, data } = useQuery(GET_PRODUCT_DETAILS, {
    variables: { handle: cavities_handles.cavities_002 },
    onCompleted: (data) => {
      if (data.productByHandle !== null) {
        const { price, title } = data.productByHandle.variants.edges[0].node;
        setSelectedVariant({
          id: data.productByHandle.variants.edges[0].node.id,
          sku: data.productByHandle.variants.edges[0].node.sku,
          parentProductTitle: data.productByHandle.title,
          handle: cavities_handles.cavities_002,
          title: title,
          price: price,
          image: data.productByHandle.images.edges[0].node.transformedSrc,
        });
      }
    },
  });

  if (loading) return null;

  if (error) {
    return `Error! ${error}`;
  }

  let disableAddToCart = false;
  if (data.productByHandle === null) disableAddToCart = true;

  let title = "";
  let totalInventory = 0;
  if (data.productByHandle !== null) {
    title = data.productByHandle.title;
    totalInventory = data.productByHandle.totalInventory;
  }

  return (
    <StyledSection id="Issue002">
      <Container>
        <Row>
          <Col xs="12">
            <h4 className="mb-5 pb-md-3 mx-auto">ISSUE 002 // January 2022</h4>
          </Col>
          <Col xs="9" md="12" className="mx-auto">
            <img src={Issue002} alt="" className="img-fluid mag" />
          </Col>
        </Row>
      </Container>

      <div className="w-100 main-text">
        <Container>
          <Row>
            <Col xs="12">
              <h1>CHEESE PLEASE</h1>
              <p className="mb-5">
                In this issue, I speak with 3 of my pastry heroes, asking them
                to each take on one of four iconic cheesecakes: New York,
                Basque, Indian Chenna Poda and French (saving the fourth,
                French, for myself). The idea here is to collate and present
                their individual know-hows into a comprehensive guide to working
                with cheese to create a stellar cheesecake.
              </p>
              <p className="mb-5">
                Also, I speak with a cheese expert to break down cheese itself,
                and also with a vegan chef to understand the workings of plant
                based dairy alternatives and how it all ties into a beautiful
                plant based cheesecake!
              </p>
              <p className="mb-5">
                You can download a digital copy of the bulletin for free or
                support the bulletin by purchasing a print copy. To sweeten the
                pot, the first 125 print purchases will come hand-signed by all
                6 contributing to the issue!
              </p>
            </Col>
            <Col
              xs="9"
              md="12"
              className="mx-auto d-flex align-items-stretch justify-content-center flex-wrap flex-md-nowrap"
            >
              <button
                disabled={disableAddToCart || totalInventory === 0}
                className="add_to_cart_btn"
                onClick={() => {
                  notifyAddedToCart();
                  cartDispatch({
                    type: "ADD_ITEM",
                    payload: { ...selectedVariant, quantity: 1 },
                  });
                  ReactGA.event({
                    category: "User Button Click",
                    action: "Added Item To Cart",
                    label: `${title} - ${selectedVariant.title}`,
                  });
                  ReactPixel.track("AddToCart", {
                    content_ids: selectedVariant.sku,
                    content_name: selectedVariant.parentProductTitle,
                    content_type: "product",
                    currency: "INR",
                    value: selectedVariant.price,
                  });
                }}
              >
                ADD TO CART
              </button>
              <a
                href="https://cdn.shopify.com/s/files/1/0227/1117/3156/files/CAVITIES_002.pdf?v=1643610992"
                target="_blank"
                rel="noreferrer noopener"
              >
                <button className="outlined ml-md-5 mt-md-0 mt-3">
                  DIGITAL PDF
                  <br />
                  (Free Download)
                </button>
              </a>
            </Col>
          </Row>
        </Container>
        <div className="white-bg"></div>
        <img src={Banner} alt="" className="banner" />
      </div>
      <div className="featured">
        <h3 className="mb-5 pb-md-3 pb-0">Featuring</h3>
        <AliceCarousel
          autoWidth
          autoPlay
          autoPlayInterval={800}
          autoPlayStrategy="none"
          //animationEasingFunction='linear'
          animationDuration={1000}
          disableButtonsControls
          disableDotsControls
          infinite
          items={imgs}
          mouseTracking
        />
      </div>
    </StyledSection>
  );
};

export default Issue2;

import React, {useEffect} from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import Chef from "../../assets/images/chef_1.jpg";
import Atelier from "../../assets/images/atelier_1.jpg";
import Chocolate from "../../assets/images/chocolate_1.jpg";
import ReactPixel from "react-facebook-pixel";

const StyledWrapper = styled.div`

  img {
    max-height: 520px;
    object-fit: cover;
    object-position: bottom;
  }

  /* Required for Carousel peek */
  /* @media (min-width: 1200px) {
    img {
      max-width: 90%;
    }
  } */

  @media (min-width: 992px) {
    margin: 120px 0px;
  }

  .row {
    margin-bottom: 50px;
    @media (min-width: 992px) {
      margin-bottom: 100px;
    }
  }

  .title {

    span {
      letter-spacing: 3px;
      font-size: 12px;
      @media (min-width: 992px) {
        font-size: 18px;
        letter-spacing: 6px;
      }
      font-weight: 600;
    }
  }

  .text-col {
    h2 {
      color: #f5e5c4;
      font-size: 40px;
      font-weight: 400;
    }

    p {
      color: #f5e5c4;
      font-size: 16px;
      font-weight: 400;
      line-height: 26px;
    }

    a {
      text-decoration: none;
      border-bottom: solid 3px;
    }

    @media (min-width: 1200px) {
      padding: 0px 100px;
    }

    .text-inner-col {
      max-width: 558px;
      margin: 0 auto;
    }
  }
`;

const About = () => {

  useEffect( () => {
    ReactPixel.pageView();
  })

  return (
    <StyledWrapper>
      <div className="container">
        <div className="row">
          <div className="title col-12 text-center">
            <span>ATELIER CHOCOLAT</span>
            <h1 className="my-lg-2">About Ether</h1>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-6 text-right">
            <img src={Chef} alt="" className="img-flui"/>
            {/* <Carousel images={[Chef, Atelier, Chocolate]} /> */}
          </div>
          <div className="col-lg-6 text-col d-flex align-items-center  mt-4 mt-lg-0">
            <div className="text-inner-col">
              <h2>The Chef</h2>
              <p>
                Having worked in chemical research in a previous life, Chef
                Prateek Bakhtiani found his passion in studying pastry at the famed
                halls of Le Cordon Bleu in Paris. His search for fine flavors
                and dessert lead him down a rabbit hole through stints as a chef
                at several Michelin starred restaurants in France, Belgium and
                the United States. He has also since trained with the
                prestigious EPGB in Tijuana, Mexico and studied Gelato at
                Carpigiani in Bologna, Italy.
              </p>
            </div>
          </div>
        </div>
        <div className="row section">
          <div className="col-lg-6 order-lg-2">
            <img src={Atelier} alt="" className="img-fluid"/>
            {/* <Carousel images={[Atelier, Chef, Chocolate]} /> */}
          </div>
          <div className="col-lg-6 text-col d-flex align-items-center  mt-4 mt-lg-0">
            <div className="text-inner-col">
              <h2>His Atelier</h2>
              <p>
                Chef Bakhtiani moved back to Mumbai in 2018 to set up his
                atelier. The atelier is a space for creativity and
                innovation in the sensory exploration of chocolate. Here, chefs are
                encouraged to experience the origins, terroir and compositions
                of chocolate and study them in the service of an inspiration.
                From these experiments we produce a set of 5 inspiration
                driven collections biannually and bring them to chocolate
                enthusiasts across the country. The Atelier also hosts a small
                viewing gallery for people that would like to come witness and
                be involved in the creative process here at Ether.
              </p>
              <p>
                <Link to="/contact">Contact Us</Link> to visit the Atelier
              </p>
            </div>
          </div>
        </div>
        <div className="row section">
          <div className="col-lg-6 text-right">
            <img src={Chocolate} alt="" className="img-fluid"/>
            {/* <Carousel images={[Chocolate, Chef, Atelier]} /> */}
          </div>
          <div className="col-lg-6 text-col d-flex align-items-center  mt-4 mt-lg-0">
            <div className="text-inner-col">
              <h2>His Chocolate</h2>
              <p>
                In classical time, Ether, was considered quintessence - the fifth
                element. That what filled the clear sky and through which light
                is brought down to man. It has since come to represent serene
                balance and equilibrium. It is from this that we hope to bring
                you chocolates with an immense respect for quiet complexity,
                subtlety and incredible nuance. 100% of the chocolate used at
                Ether is single origin and in most cases single plantation pure
                coverture chocolate, with origins ranging from Peru to Indonesia
                to Madagascar. Come and explore the quiet elegance of fine
                chocolate.
              </p>
              <p>
                <Link to="/shop">Explore the Collections</Link>
              </p>
            </div>
          </div>
        </div>
      </div>
    </StyledWrapper>
  );
};

export default About;

import React, { useEffect } from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import { ApolloProvider } from "@apollo/react-hooks";
import client from "../../apollo";
import { ThemeProvider } from "styled-components";
import Theme from "../../Theme";
import Header from "../Header";
import Footer from "../Footer";
import GlobalStyle from "./globalStyle";
import Home from "../Home/";
import Shop from "../Shop/";
import About from "../About/";
import Contact from "../Contact/";
import Register from "../Auth";
import Cart from "../Cart";
import ProductDetail from "../ProductDetail/ProductDetail";
import { LoadingProvider } from "../context/LoadingContext";
import { CartProvider } from "../context/CartContext";
import Spinner from "../Spinner";
import TermsAndConditions from "../TermsAndConditions";
import PrivacyPolicy from "../PrivacyPolicy";
import ScrollToTop from "../ScrollToTop";
import Analytics from "react-router-ga";
import NotFound from "../NotFound";
import ReactGA from "react-ga";
import ReactPixel from "react-facebook-pixel";
import ProductCardTransition from "../ProductCardTransition";
import { TransitionProvider } from "../context/TransitionContext";
import MonthlySpecials from "../Shop/MonthlySpecials";
import Cavities from "../Cavities"
import {Helmet} from "react-helmet";

const App = () => {
  const options = {
    autoConfig: true, 	// set pixel's autoConfig
    debug: false, 		// enable logs
  };
  ReactPixel.init('1152088395130202', options);


  useEffect(() => {
    const gaInstance = () => ReactGA.initialize("UA-149841032-1", { debug: false });
    return () => {
      gaInstance();
    };
  }, []);

  return (
      <div>
        <Helmet>
          <meta charSet="utf-8" />
          <title>Ether: Atelier Chocolat</title>
          <link rel="canonical" href="http://etherchocolate.com" />
          <meta
              name="description"
              content="Chocolate with an immense respect for quiet complexity, subtlety and incredible nuance."
          />
          <meta property="og:title" content="Ether: Atelier Chocolat"/>
          <meta property="og:description" content="The Ether atelier is a space for creativity and innovation in the sensory exploration of chocolate. Here, chefs are encouraged to experience the origins, terroir and compositions of chocolate and study them in the service of an inspiration. From these experiments we produce a set of 5 inspiration driven collections biannually and bring them to chocolate enthusiast across the country."/>
          <meta property="og:image" content="https://cdn.shopify.com/s/files/1/0227/1117/3156/files/chef.jpg"/>
        </Helmet>
    <ApolloProvider client={client}>
      <ThemeProvider theme={Theme}>
        <BrowserRouter>
          <ScrollToTop />
          <div className="App">
            <GlobalStyle />
            <LoadingProvider>
              <TransitionProvider>
                <CartProvider>
                  <Header />
                  <div className="main">
                    <Spinner />
                    <ProductCardTransition />
                    <Analytics id="UA-149841032-1">
                      <Switch>
                        <Route exact path="/" component={Home} />
                        <Route exact path="/shop" component={Shop} />
                        <Route exact path="/specials" component={MonthlySpecials} />
                        <Route exact path="/about" component={About} />
                        <Route exact path="/contact" component={Contact} />
                        <Route exact path="/register" component={Register} />
                        <Route exact path="/cart" component={Cart} />
                        <Route
                          exact
                          path="/terms-and-conditions"
                          component={TermsAndConditions}
                        />
                        <Route
                          exact
                          path="/privacy-policy"
                          component={PrivacyPolicy}
                        />
                        <Route
                          exact
                          path="/shop/:handle"
                          component={ProductDetail}
                        />
                        <Route exact path='/cavities' component={Cavities} />
                        <Route path="*" component={NotFound} />
                      </Switch>
                    </Analytics>
                  </div>
                  <Footer />
                </CartProvider>
              </TransitionProvider>
            </LoadingProvider>
          </div>
        </BrowserRouter>
      </ThemeProvider>
    </ApolloProvider>
      </div>
  );
};

export default App;

import React from "react";
import styled from "styled-components";

import HeroImg from "../../assets/images/cavities/Hero_image_opt_2.jpg";
import Logo from "../../assets/images/cavities/Cavities.svg";

const StyledSection = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: white;
  background-image: url(${HeroImg});
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  min-height: 100vh;
  width: 100%;
  font-family: "Kollektif", "Volte", sans-serif;
  font-weight: 700;
  font-size: 30px;
  letter-spacing: 0.345em;
  text-align: center;
  text-transform: uppercase;
  padding: 5% 20%;

  img {
    height: 230px;
    position: relative;
    top: -10px;
  }

  @media (max-width: 768px) {
    font-size: 14px;
    min-height: unset;
    padding: 5rem 1rem;

    img {
      padding: 0 15%;
      height: 180px;
    }
  }
`;

const Hero = () => {
  return (
    <StyledSection>
      Way too many
      <img src={Logo} alt="Cavities" className="img-fluid mb-md-5 mb-3 logo" />A
      Professional Pâtisserie Bulletin Series
    </StyledSection>
  );
};

export default Hero;

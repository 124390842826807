import React, { useContext } from "react";
import {
  TransitionContext,
} from "../context/TransitionContext";
import { withRouter } from "react-router-dom";
import styled from "styled-components";

import { stringifyPrice } from "../../utils";

const StyledWrapper = styled.div`

  margin-bottom: 90px;
  @media (min-width: 992px) {
    margin-bottom: 120px;
  }

  p {
    margin-bottom: 0;
  }

  .product-card {
    display: block;
    width: 75%;
    margin: 0 auto;
  }


  .product-title {
    color: ${props => props.theme["lightGray"]};
    font-size: 28px;
    font-weight: 500;
    letter-spacing: 0.35px;
  }

  .tagline {
    color: ${props => props.theme["lightGray"]};
    font-size: 16px;
    font-weight: 400;
    line-height: 23px;
  }

  .price {
    color: ${props => props.theme["lightGray"]};
    font-size: 18px;
    font-weight: 500;
    letter-spacing: 0.23px;
  }
`;

const FeaturedProductCard = ({
  history,
  product: { node: product },
  cardSize,
  subtitle,
}) => {
  const { popUpImageOutOfCurrentPosition } = useContext(TransitionContext);

  const handleCardClick = (event) => {
    const dim = event.currentTarget.getBoundingClientRect()
    const image = event.currentTarget.parentElement.getElementsByTagName("img")[0];
    popUpImageOutOfCurrentPosition(image, dim);
    history.push(`/shop/${product.handle}`);
  }

  return (
    <StyledWrapper className={`${cardSize}`}>
      <div className="card-bg px-3">
        <div
          className="product-card"
          onClick={(event) => handleCardClick(event)}
          style={{ cursor: "pointer" }}
        >
          <div className="img-wrapper">
            <img
              src={product.images.edges[0].node.transformedSrc}
              className="product-card-image img-fluid w-100"
              alt=""
            />
          </div>
          <p className="product-title mt-3">{product.title}</p>
          <p className="tagline" style={{ minHeight: 50 }}>
            {subtitle}
          </p>
          <p className="price">
            {stringifyPrice(product.priceRange.minVariantPrice.amount*1.18)}
          </p>
        </div>
      </div>
    </StyledWrapper>
  );
};

export default withRouter(FeaturedProductCard);

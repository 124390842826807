import React, {useEffect} from "react";
import styled from "styled-components";
import Instagram from "../../assets/images/instagram.svg";
import CustomMap from '../CustomMap/';
import ReactPixel from "react-facebook-pixel";

const StyledWrapper = styled.div`
  padding: 50px 0px;
  @media (min-width: 992px) {
    padding: 100px 0px;
  }

  .info-col {
    div {
      @media (max-width: 992px) {
        margin-bottom: 30px;
      }
    }
  }

  h1 {
    color: #f5e5c4;
    font-size: 40px;
    font-weight: 400;
  }

  p,
  span {
    color: #f5e5c4;
    font-size: 19px;
    font-weight: 400;
  }

  h2 {
    color: #f5e5c4;
    font-size: 20px;
    font-weight: 600;
  }

  a.address {
    color: ${props => props.theme["lightGray"]};
    font-size: 16px;
    font-weight: 400;
    letter-spacing: 0.2px;
    line-height: 31px;
    text-decoration: underline;
  }
  
  a.address:hover {
      color: ${props => props.theme["lightGray"]};
      text-decoration: underline;

  }

  .map-container {
    position: relative !important;
    overflow: hidden;
    div {
      max-width: 100% !important;
    }
  }

`;

const Contact = () => {

    useEffect( () => {
        ReactPixel.pageView();
    })

    return (
        <StyledWrapper>
            <div className="container">
                <div className="row">
                    <div className="info-col col-lg-6 d-flex flex-column justify-content-between">
                        <div>
                            <h1>Visit the Atelier in Mumbai</h1>
                            <p>
                                Want to discuss gifting, collaborations or just share a coffee
                                and see how chocolate is made? Visit us at our Atelier.
                            </p>
                        </div>

                        <div>
                            <h2>
                                Visit the Atelier: <span>(By Appointment Only)</span>
                            </h2>
                            <a className="address" href="https://maps.app.goo.gl/oho6c3G6sH2NKP1XA" target="_blank" rel="noopener noreferrer">
                                Gala No. A6, Royal Industrial Estate,
                                <br/>
                                Naigaon Cross Road,
                                <br/>
                                Wadala West, Mumbai 400031
                            </a>
                        </div>

                        <div>
                            <h2>Contact: </h2>
                            <span className="d-block">
                <a href="tel:+9191378 01624">+91 91378 01624</a>
              </span>
                            <span className="d-block">
                <a href="mailto:hello@etherchocolate.com">hello@etherchocolate.com</a>
              </span>
                            <a
                                className="social my-3"
                                target="_blank"
                                rel="noopener noreferrer"
                                href="https://www.instagram.com/etherchocolate/"
                            >
                                <div className="mt-3 d-flex align-items-center">
                                    <img src={Instagram} alt=""/>
                                    <span className="ml-3">@EtherChocolate</span>
                                </div>
                            </a>
                        </div>
                    </div>
                    <div className="map-container col-lg-6 px-0 mx-3 mx-lg-0" style={{minHeight: 600}}>
                        <CustomMap/>
                    </div>
                </div>
            </div>
        </StyledWrapper>
    );
};

export default Contact;

import {
  createGlobalStyle
} from 'styled-components'

const GlobalStyle = createGlobalStyle`

  html, body {
    width: 100%;
    height: 100%;
    background-color: black;
    margin: 0;
    color: ${props => props.theme['sundance']};
    font-family: Volte, sans-serif;
  }

  .App {
    min-height: 100%;
    display: grid;
    grid-template-rows: auto 1fr auto;
    grid-template-columns: 100%;
  }

  a {
      color: inherit;
      text-decoration: none;
      cursor: pointer;

      &:hover {
          color: inherit;
          text-decoration: none;
      }
  }
  
   h1 {
    color: #f5e5c4;
    font-size: 44px;
    @media (min-width: 768px) {
      font-size: 58px;
    }
    font-weight: 300;

    margin-bottom: 90px;
    @media (min-width: 992px) {
      margin: 90px 0 120px 0;
    }

  }
  
    h2 {
    color: #f5e5c4;
    font-size: 44px;
    @media (min-width: 768px) {
      font-size: 50px;
    }
    font-weight: 300;
  }

  .btn-golden {
    background-color: ${props => props.theme['sundance']};
    color: #ffffff;
    font-size: 16px;
    font-weight: 500;
    letter-spacing: 1px;
    line-height: 16px;
    text-transform: uppercase;
    padding-top: 15px;
    padding-bottom: 15px;
    border-radius: 0;
    outline: none;
    box-shadow: none;

    &:focus {
      box-shadow: none;
    }
  }
  
    .btn-golden-border {
    border: 2px solid ${props => props.theme['sundance']};
    color: #ffffff;
    font-size: 16px;
    font-weight: 500;
    letter-spacing: 1px;
    line-height: 16px;
    text-transform: uppercase;
    padding-top: 15px;
    padding-bottom: 15px;
    border-radius: 0;
    outline: none;
    box-shadow: none;

    &:focus {
      box-shadow: none;
    }
    
    &:hover {
      color: white;
    }
  }

  input[type=email], input[type=text], input[type=password] {
    border-radius: 0;
    outline: none;
    box-shadow: none;
    border: 1px solid #979797;

    &:focus {
      border: 1px solid #979797;
      border-radius: 0;
      outline: none;
      box-shadow: none;
    }

    font-size: 20px;
  }

  form {
    label {
      color: ${props => props.theme['lightGray']};
      font-size: 14px;
      font-weight: 400;
      letter-spacing: 0.44px;
      line-height: 16px;
      text-transform: uppercase;
    }
  }

  .modal-container {
    .modal-content {
      background-color: black !important;
      color: #f4f4f4;
      padding: 80px 40px;
      opacity: 0.8;
    }
    span.close-btn {
      position: absolute;
      top: 2%;
      right: 5%;
      cursor: pointer;
      font-size: 36px;
      color: unset;
    }
  }

  .modal-launch {
    color: #c3915b;
    font-size: 16px;
    font-weight: 500;
    letter-spacing: 0.5px;
    text-transform: uppercase;
    cursor: pointer;
  }

  .toast-bronze-background {
    background-color: ${props => props.theme['sundance']};
    color: #272626;
    font-size: 16px;
    font-weight: 600;
    letter-spacing: 0.5px;
    line-height: 16px;
    text-transform: uppercase;
    font-family: Volte, sans-serif;
  }

  .toast-progress-bar {
    background: #272626;
  }

  .main {
    margin-top: 72px;
    @media (max-width: 992px) {
      margin-top: 120px;
    }
  }

  .animate-underline {
    width: fit-content;
    max-width: fit-content;
    height: 5px !important;

    &::after {
      content: "";
      display: block;
      background-color: #c3915b;
      width: 18% !important;
      height: 5px !important;
      margin: 0 auto;
      -webkit-transform: scale(0.1, 1);
      transform: scale(1, 1);
      -webkit-animation: transformer 0.3s ease-in 0s 1;
      animation: transformer 0.3s ease-in 0s 1;
    }

    @-webkit-keyframes transformer {
      from {
          -webkit-transform: scale(0.1);
      }
      to {
          -webkit-transform: scale(1, 1);
      }
    }
    @keyframes transformer {
        from {
            transform: scale(0.1);
        }
        to {
            transform: scale(1, 1);
        }
    }
  }
  
  // for product card styling
  .product-card {
    overflow: hidden;
  }

  .product-card:hover > .product-title {
    color: ${props => props.theme["sundance"]};
    transition: color 0.5s;
  }

  .product-card > * img {
    transform: scale(1);
    transition: transform 1s ease-in-out;
  }

  .product-card:hover > * img {
    transform: scale(1.1);
    transition: transform 1s ease-in-out;
  }

  .img-wrapper {
    overflow: hidden;
  }

`

export default GlobalStyle;
import React, { useEffect, useContext } from "react";
import { useQuery } from "@apollo/react-hooks";
import { gql } from "apollo-boost";
import ScrollAnimation from 'react-animate-on-scroll';
import { LoadingContext } from "../context/LoadingContext";
import styled from "styled-components";
import { ProductCard } from "../ProductCard";
import ReactPixel from "react-facebook-pixel";

const StyledWrapper = styled.div`
  margin-top: 20px;
  margin-bottom: 20px;

  h1 {
    padding: 0px 15px;

    color: #f5e5c4;
    font-size: 44px;
    @media (min-width: 768px) {
      font-size: 58px;
    }
    font-weight: 300;
    letter-spacing: -1px;

    &::after {
      width: 61px;
      height: 7px;
      background-color: #c3915b;
    }
  }

  p.desc {
    padding: 0px 15px;
    color: #f5e5c4;
    font-size: 19px;
    font-weight: 400;
    line-height: 30px;
    margin-bottom: 3rem;
  }

  .side-scroll {
    flex-wrap: nowrap;
    overflow-x: scroll;

    div {
      margin: 0 15px;
      width: 40%;
      display: flex;
    }
  }
  
  @media (min-width: 992px) {
    margin-top: 100px;
  }
  
`;

const GET_MONTHLY_SPECIALS = gql`
  query getMonthlySpecials($handle: String!) {
    collectionByHandle(handle: $handle) {
      title
      description
      products(first: 20) {
        edges {
          node {
            title
            handle
            priceRange {
              minVariantPrice {
                amount
              }
            }
            images(first: 1) {
              edges {
                node {
                  transformedSrc(maxHeight: 575)
                }
              }
            }
          }
        }
      }
    }
  }
`;

const MonthlySpecials = () => {

  useEffect( () => {
    ReactPixel.pageView();
  })

  const { showLoading, hideLoading } = useContext(LoadingContext);

  useEffect(() => {
    showLoading();
  }, [showLoading]);

  const handle = "monthly-specials";

  const { loading, error, data } = useQuery(GET_MONTHLY_SPECIALS, {
    variables: { handle },
    onCompleted: data => {
      hideLoading();
    }
  });

  if (loading) return null;

  if (error) {
    return `Error! ${error}`;
  }

  return (
    <StyledWrapper>
      {!loading && (
        <ScrollAnimation animateIn='fadeIn' animateOnce={true}>
          <div className="container">
            <div className="row">
              <div className="col-12 col-lg-8">
                <div className="row">
                  <h2 className="col">
                    {data.collectionByHandle.title}
                  </h2>
                </div>
                <p className="desc px-0">{data.collectionByHandle.description}</p>
              </div>
            </div>
            <div className="row">
              {data.collectionByHandle.products.edges.map(product => (
                  <ProductCard key={product.id} product={product.node} cardSize="col-6 col-md-4 col-lg-3" />
              ))}
            </div>
          </div>
        </ScrollAnimation>
      )}
    </StyledWrapper>
  );
};

export default MonthlySpecials;

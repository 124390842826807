import styled from "styled-components";

const StyledWrapper = styled.div`
  @media (min-width: 992px) {
    margin-top: 120px;
    margin-bottom: 120px;
  }

  h2 {
    color: ${props => props.theme["lightGray"]};
    font-size: 40px;
    letter-spacing: 0.5px;
    margin-bottom: 0;
  }

  .shipping,
  .description,
  .signature {
    color: ${props => props.theme["lightGray"]};
    font-family: Volte, sans-serif;
    font-size: 16px;
    font-weight: 400;
  }

  .signature {
    img {
      width: 175px;
    }
  }

  .price {
    color: #ffffff;
    font-size: 18px;
    font-weight: 500;
  }

  .add-cart-btn {
    background: #C9A850;
    width: 300px;
    height: 51px;
    max-width: 100%;
    border-radius: 0px;
    color: #272626;
    font-size: 16px;
    font-weight: 600;
  }

  .align-content {
    align-self: center;
  }

  .react-datepicker__current-month {
    text-align-last: left;
    color: #F5E5C4;
    font-size: 18px;
    padding-left: 15px;
  }

  .react-datepicker__navigation--previous {
    left: unset;
    right: 30px;
    border-right-color: #F5E5C4;
    outline: none;
    margin-top: 4px;
  }

  .react-datepicker__navigation--previous:hover {
    border-right-color: #F5E5C4;
  }

  .react-datepicker__navigation--next {
    border-left-color: #F5E5C4;
    outline: none;
    margin-top: 4px;
  }

  .react-datepicker__navigation--next:hover {
    border-left-color: #F5E5C4;
  }

  .react-datepicker__day {
    color: #fff;
  }

  .react-datepicker__day-name {
    color: #F5E5C4;
  }

  .react-datepicker {
    font-size: 16px;
  }

  .react-datepicker__month {
    margin: 0.6rem;
  }

  .react-datepicker__day--keyboard-selected {
    background-color: transparent;
    border: 1px solid #C9A850;
    outline: none !important;
  }

  .react-datepicker__day--selected {
    border: 1px solid #C9A850;
    background-color: transparent;
    border-radius: 0;
    outline: none;
  }

  .react-datepicker__day:hover {
    border-radius: 0px;
    background: #C9A850;
  }

  .react-datepicker-popper {
    top: -76px !important;
    left: 62px !important;
    @media (max-width: 600px) {
      top: -75px !important;
      left: 51px !important;
    }
  }

  .react-datepicker__day--disabled {
    color: #5a5a5a;
  }

  .picked-date {
    color: #F4F4F4;
    font-size: 16px;
  }

  .padding-date-box {
    border-bottom: 1px solid #C9A850;
    max-width: 240px;
  }

  input[type='radio'] {
    -webkit-appearance: none;
    width: 15px;
    height: 15px;
    border: 1px solid #C9A850;
    border-radius: 50%;
    outline: none;
  }

  input[type='radio']:before {
    content: '';
    display: block;
    width: 50%;
    height: 50%;
    margin: 27% auto;
    border-radius: 50%;
  }

  input[type='radio']:checked:before {
    background: #C9A850;
  }

  .width-cal {
    width: 260px;
  }

  .react-datepicker {
    background-color: #000000;
    border-radius: 0;
    border: 1px solid #C9A850;
  }

  .react-datepicker__header {
    background-color: #000000;
    border: none;
  }

  .calender-title {
    margin: 0px;
    padding: 0px;
    color: #FFFFFF;
    font-size: 16;
    font-weight: 600;
  }

  .time-slot-container {
    border: 1px solid #C9A850;
    background: black;
    display: flex;
    justify-content: center;
    position: relative;
    bottom: 9px;
    padding-top: 15px;
    padding-bottom: 15px;
    padding-left: 15px;
  }

  .label-container {
    cursor: pointer;
  }

  .day-time {
    color: #F5E5C4;
  }

  .time-day {
    color: #ffffff;
    font-weight: 600;
  }

  .calender-text {
    display: table-cell;
    vertical-align: middle;
    padding-left: 10px;
    padding-bottom: 20px;
  }

  .calender-under-text {
    color: white;
    font-size: 16;
  }

  .calender-icon {
    display: table-cell;
    vertical-align: middle;
    width: 50px;
  }

  .btn-golden {
    width: 300px;
    max-width: 100%;
  }

  .subtitle {
    color: ${props => props.theme['lightGray']};
    font-size: 19px;
    font-weight: 400;
    line-height: 30px;
  }

  h3 {
    color: #ffffff;
    font-size: 16px;
    font-weight: 600;
    letter-spacing: 1.6px;
    line-height: 30px;
    text-transform: uppercase;
  }

  .collection-icon-holder {
    max-width: 400px;
  }

  .flavor-box, .tasting-box {
    p {
      color: #ffffff;
      font-size: 18px;
      font-weight: 400;
      letter-spacing: 0.79px;
      line-height: 27px;
      margin-bottom: 0;
    }
  }

  .chocolate-tasting-box {
    p {
      color: #ffffff;
      font-size: 16px;
      font-weight: 400;
      letter-spacing: 0.79px;
      line-height: 27px;
      margin-bottom: 0;
    }

    .image-txt {
      color: white;
      padding-top:10px;
    }

    .item-images{
      height:30px;
    }

    .second-div {
      font-size: 14px;
      opacity: 0.5;
      letter-spacing: 1.6px;
      line-height: 30px;
      color: #ffffff;
    }
    
    .light-text {
      opacity: 0.5;
      font-size: 14px;
    }
  }

    .extra-info-collection, .extra-info {

      margin: 90px 0px;

      @media (min-width: 992px) {
        margin: 150px 0px;
      }
    }

    .pairing-col {
      display: flex;
      align-items: flex-start;

      @media (max-width: 992px) {
        margin-bottom: 40px;
      }

      img {
        margin-right: 20px;
      }

      h4 {
        color: #ffffff;
        font-size: 12px;
        font-weight: 600;
        letter-spacing: 0.79px;
        text-transform: uppercase;
      }

      p {
        color: #ffffff;
        font-size: 14px;
        font-weight: 400;
        letter-spacing: 0.62px;
        line-height: 20px;
        margin-bottom: 0;
      }
    }

    .collection-contents {
      span {
        color: #ffffff;
        font-size: 14px;
        font-weight: 400;
        line-height: 18px;
        margin-left: 20px;
      }
    }

    .food-pairing-icon {
      width: 30px;
    }

    .gst-disclaimer {
      font-weight: 400;
      font-size: 15px;
    }

    .out-of-stock {
      width: 444px;
      height: 34px;
      color: ${props => props.theme['sundance']};
      font-family: Volte, sans-serif;
      font-size: 19px;
      font-weight: 400;
      line-height: 30px;
      margin-bottom: 100px;
    }
`;

export default StyledWrapper;
import React, { useContext } from "react";
import { CartContext } from "../context/CartContext";
import styled from "styled-components";
import gql from "graphql-tag";
import { useMutation } from "@apollo/react-hooks";
import { LoadingContext } from "../context/LoadingContext";

const StyledWrapper = styled.div`
  z-index: 1;
`;

const CREATE_CHECKOUT = gql`
  mutation CreateCheckout($input: CheckoutCreateInput!) {
    checkoutCreate(input: $input) {
      checkout {
        id
        webUrl
        order {
          orderNumber
          statusUrl
        }
      }
    }
  }
`;

const CheckoutButton = ({OrderNotes,from}) => {
  const { items } = useContext(CartContext);
  const { showLoading } = useContext(LoadingContext);


  const [createCheckout, { data, loading, error }] = useMutation(
    CREATE_CHECKOUT
  );
  
  const dateOnly = val => {
    if(!val) return null;
    return val.getFullYear() + "/" + (val.getMonth() + 1) + "/" + val.getDate();
  }

  const checkoutCreateInput = {
    lineItems: []
  };

  Object.values(items).forEach(item => {
    if(item.deliveryDate){
      checkoutCreateInput.lineItems.push({
        variantId: item.id,
        quantity: item.quantity,
        customAttributes:[
          {
            key:"Delivery Date",
            value: dateOnly(item.deliveryDate),
          },
          {
            key:"Time Slot",
            value:item.timeSlot,
          }
        ],
      });
    } else {
      checkoutCreateInput.lineItems.push({
        variantId: item.id,
        quantity: item.quantity,
      })
    }
    checkoutCreateInput.note = OrderNotes + "-FROM: " + from;

  });

  if (loading) {
    return null;
  }

  if (error) {
    alert("Something went wrong. Please refresh and try again.");
  }

  if (data) {
    window.open(data.checkoutCreate.checkout.webUrl, "_self");
  }

  return (
    <StyledWrapper>
      <button
        onClick={() => {
          showLoading();
          createCheckout({ variables: { input: checkoutCreateInput } });
        }}
        className="btn btn-golden mb-3"
      >
        CHECKOUT
      </button>
    </StyledWrapper>
  );
};

export default CheckoutButton;

import React from 'react';
import { Col, Container, Row } from 'reactstrap';
import styled from 'styled-components';

import Issue003 from '../../assets/images/cavities/Issue003.jpg'
import BG from '../../assets/images/cavities/Issue003-BG.png'

const StyledSection = styled.section`
    background-color: white;
    background-image: url(${BG});
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center top;
    color: black;
    padding: 5rem 0;
    text-align: center;

    img {
        margin-top: 2rem;
    }

    @media (max-width: 768px) {
        padding: 4rem 0;
        h4 { font-size: 16px; }
    }
`;

const Issue3 = () => {

  return (
    <StyledSection id='Issue003'>
        <Container>
            <Row>
                <Col xs="12">
                    <h4 className='mx-auto'>STAY TUNED FOR ISSUE 003!</h4>
                </Col>
                <Col xs="9" md="12" className='mx-auto'>
                    <img src={Issue003} alt='' className='img-fluid mag' />
                </Col>
            </Row>
        </Container>
    </StyledSection>
  );
};

export default Issue3;

import React, {useEffect} from "react";
import OpenerImage from "../../assets/images/splash_screen_opener.jpg";
import styled, {createGlobalStyle} from "styled-components";
import {Link} from "react-router-dom";
import ReactPixel from "react-facebook-pixel";
import { useQuery } from "@apollo/react-hooks";
import { GET_FEATURED_COLLECTION_TITLE_BY_HANDLE, getFeaturedCollectionFullTitle, FEATURED_COLLECTION_HANDLE } from "../../utils";

const Video = window.innerWidth > 992 ? "https://cdn.shopify.com/s/files/1/0227/1117/3156/files/ether_home_video.mp4?129" : "https://cdn.shopify.com/s/files/1/0227/1117/3156/files/Ether_Portrait_BW_Edit14_1_1.mp4?344";
const StyledWrapper = styled.div`

  display: flex;
  align-items: center;
  justify-content: center;

  height: 100vh !important;
  width: 100%;

  overflow-x: hidden;

  h1 {

    &::after {
      width: 61px;
      height: 7px;
      background-color: #c3915b;
    }
  }

  img.logo {
    width: 140px;
    @media (min-width: 992px) {
      width: 200px;
    }
  }

  h1 {
    color: #ffffff;
    font-size: 68px;
    font-weight: 400;
    letter-spacing: -1px;
    line-height: 64px;
  }

  span {
    color: #c3915b;
    letter-spacing: 6px;
    font-weight: 600;
    font-size: 16px;
  }

  .btn-golden, .btn-golden-border {
    width: 250px;
  }


  .video-overlap {
    position: absolute;
    top: 30%;
    @media (min-width: 992px) {
      top: 35%;
    }
    left: 0;
    right: 0;
  }

  p {
    color: #c3915b;
    text-transform: uppercase;
    margin-top: 70px !important;
    font-weight: 600;
    font-size: 16px;
    letter-spacing: 3px;
  }
  
  #experienceHeroVideo {
    min-height: 100vh;
    min-width: 100vw;
    position: relative;
    overflow: hidden;
    z-index: 1;
    animation-name: fadeIn;
    animation-duration: 2s;
    background-color: #000;
}


#video-background {
    position: absolute;
    right: 0;
    top: -30px;
    min-width: 100%;
    min-height: 120vh;
    width: auto;
    height: 100%;
    -o-object-fit: cover;
    object-fit: cover;
    margin-top: -90px;
    object-position: top;
}

#experienceHeroVideo * {
    z-index: 5;
}

.logo-with-byline {
  width: 275px;
  margin: 0 auto;
  .logo {
    width: 100%;
  }
  .tagline {
    
  }
}

.bg-dark-gradient {
  position: absolute;
    top: -30px;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #000000;
    opacity: 0.3;
    z-index: 10 !important;
}

.video-overlap {
  z-index: 11 !important;
}

@keyframes fadeIn {
  from { opacity: 0; }
  to   { opacity: 1; }
}

`;

const OverrideTopMargin = createGlobalStyle`
  .main {
    margin-top: 0px !important;
  }
  #header{
    display: none;
  }
`

const Home = () => {

    useEffect( () => {
        ReactPixel.pageView();
    })

    const { data : collectionData } = useQuery(GET_FEATURED_COLLECTION_TITLE_BY_HANDLE, {
      variables: {handle: FEATURED_COLLECTION_HANDLE},
    });

    return (
        <StyledWrapper>
          <OverrideTopMargin />

            <div id="experienceHeroVideo" className="d-flex flex-column align-items-center">
                <div className="bg-dark-gradient"></div>
                <video playsinline="true" loop="true" muted="true" autoplay="true" poster={OpenerImage} id="video-background">
                    <source src={Video} type="video/mp4"/>
                </video>
                <div className="video-overlap text-center">
                    <div className="logo-with-byline mb-lg-5">
                        <img className="logo img-fluid"
                             src="https://cdn.shopify.com/s/files/1/0227/1117/3156/files/Ether-Logo-Byline.svg"
                             alt="ether logo"/>
                    </div>
                    <Link to="/shop" className="d-block">
                        <button className="btn btn-golden my-2 mt-5">
                            SHOP THE COLLECTION
                        </button>
                    </Link>
                    <Link to="/specials" className="d-block">
                        <button className="btn btn-golden-border my-2">
                            SHOP CAKE
                        </button>
                    </Link>
                    {collectionData && <p>{getFeaturedCollectionFullTitle(collectionData.collectionByHandle.title)}</p>}
                </div>
            </div>

        </StyledWrapper>
    );
};

export default Home;

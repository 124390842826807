import styled from "styled-components";

const StyledWrapper = styled.div `
  
  .alice-carousel:hover .alice-carousel__prev-btn {
    opacity: 0.6; 
  }

  .alice-carousel__prev-btn{
    position: absolute;
    top: 40%;
    left: 20px;
    width: auto;
    height: auto;
    font-size: 2rem;
    text-align: center;
    opacity: 0;
    padding: 0;
    transition: opacity 0.4s; 

    @media (max-width: 992px) {
      display: none;
    }
  }

  .alice-carousel__next-btn {
    display: none;
  }

  .alice-carousel__prev-btn-item, 
  .alice-carousel__prev-btn-item:hover {
    width: 4rem;
    color: #000;
    transform: scaleY(1.5);
    padding-top: 10px;
    background-color: rgba(195,145,91);
  }

  

  .alice-carousel__dots {
    @media (min-width: 992px) {
      text-align: right;
    }

    margin: 15px 0px;
    li {
      border-radius: 0;
      height: 3px;
      width: 30px;
      background-color: white;

      &:hover {
        background-color: white;
      }
    }

    li.__active {
      background-color: ${props => props.theme["sundance"]};
    }
  }

  .alice-carousel__stage-item {

    @media (max-width: 992px) {
        text-align:center;
    }
  }

  .carousel-img{
    width: 550px;
  }

`;

export default StyledWrapper;
import React, {useContext} from "react";
import {withRouter} from "react-router-dom";
import styled from "styled-components";
import {stringifyPrice} from "../../utils";
import {
    TransitionContext,
} from "../context/TransitionContext";

const StyledWrapper = styled.div`
  margin-bottom: 90px;
  @media (min-width: 992px) {
    margin-bottom: 120px;
  }

  p {
    margin-bottom: 0;
  }

  .product-title {
    color: ${props => props.theme["lightGray"]};
    font-size: 18px;
    font-weight: 500;
    letter-spacing: 0.35px;
  }

  .tagline {
    color: ${props => props.theme["lightGray"]};
    font-size: 16px;
    font-weight: 400;
    line-height: 23px;
  }

  .price {
    color: ${props => props.theme["lightGray"]};
    font-size: 18px;
    font-weight: 500;
    letter-spacing: 0.23px;
  }
`;

const ProductCard = ({history, product, cardSize}) => {
    const {popUpImageOutOfCurrentPosition} = useContext(TransitionContext);
    const handleCardClick = (event) => {
        const dim = event.currentTarget.getBoundingClientRect()
        const image = event.currentTarget.parentElement.getElementsByTagName("img")[0];
        /**
         * The DOM element had its coordinate values set to 0.
         * DOMRect seems to fix that.
         */
        popUpImageOutOfCurrentPosition(image, dim);
        history.push(`/shop/${product.handle}`);
    }
    if (product.title && product.priceRange.minVariantPrice.amount && product.images.edges.length > 0) {
        return (
            <StyledWrapper className={`${cardSize}`}>
                <div
                    className="product-card"
                    onClick={(event) => handleCardClick(event)}
                    style={{cursor: "pointer"}}
                >
                    <div className="img-wrapper">
                        <img
                            src={product.images.edges[0].node.transformedSrc}
                            className="img-fluid w-100"
                            alt=""
                        />
                    </div>
                    <p className="product-title mt-3">{product.title}</p>
                    <p className="price">
                        {stringifyPrice(product.priceRange.minVariantPrice.amount*1.18)}
                    </p>
                </div>
            </StyledWrapper>
        );
    }
    return null;
};

export default withRouter(ProductCard);

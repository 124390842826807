import React from "react";
import styled from "styled-components";
import Minus from "../../assets/images/minus.svg";
import Plus from "../../assets/images/plus.svg";

const StyledWrapper = styled.div`
  display: flex;
  align-items: center;

  .edit-quantity {
    border: none;
    width: 32px;
    height: 32px;
    background-color: #ffffff;
    cursor: pointer;
  }

  span {
    color: #ffffff;
    font-size: 23px;
    font-weight: 400;
    line-height: 30px;
    width: 30px;
    text-align: center;
  }
`;

const QuantityEditor = ({ quantity, onIncrement, onDecrement }) => {

  return (
    <StyledWrapper>
      <div className="edit-quantity d-flex align-items-center justify-content-center" onClick={onDecrement}>
        <img src={Minus} className="img-fluid" alt="" />
      </div>
      <span className="mx-3">{quantity}</span>
      <div className="edit-quantity d-flex align-items-center justify-content-center" onClick={onIncrement}>
        <img src={Plus} className="img-fluid" alt="" />
      </div>
    </StyledWrapper>
  );
};

export default QuantityEditor;

import React, { useContext } from "react";
import { LoadingContext } from "./context/LoadingContext";
import spinner from "../assets/images/bronze_loading.gif";

const Spinner = () => {
  const { loadingCount } = useContext(LoadingContext);

  return (
    <div>
      {loadingCount > 0 && (
        <div
          style={{
            position: "absolute",
            width: "100%",
            height: "100%",
            zIndex: 1,
            background: '#000000'
          }}
          className="d-flex justify-content-center align-items-center"
        >
          <img style={{ maxWidth: 160 }} src={spinner} alt="" />
        </div>
      )}
    </div>
  );
};

export default Spinner;

import React, { useContext } from "react";
import { CartContext } from "../context/CartContext";
import { Link } from "react-router-dom";
import styled from "styled-components";
import QuantityEditor from './QuantityEditor';
import { stringifyPrice } from "../../utils";

const StyledWrapper = styled.div`

  border-bottom: 1px solid ${props => props.theme["lightGray"]};
  border-top: ${props => (props.first === true ? "1px solid #d9d9d9" : "none")};

  @media (max-width: 992px) {
    margin-left: 0px;
    margin-right: 0px;
  }

  h2.parent-product-title {
    font-size: 18px;
    color: #f5e5c4;
    font-weight: 600;
    letter-spacing: 0.23px;
  }

  p.variant-title {
    color: #f5e5c4;
    font-family: Volte, sans-serif;
    font-size: 14px;
    font-weight: 400;
    letter-spacing: 0.18px;
  }

  p.quantity {
    color: #d9d9d9;
    font-size: 16px;
    font-weight: 400;

    .box {
      padding: 5px 20px;
      border: 1px solid #d9d9d9;
    }
  }

  p.price {
    color: #ffffff;
    font-size: 20px;
    font-weight: 500;
  }

  .remove {
    color: #c3915b;
    font-size: 16px;
    font-weight: 600;
    letter-spacing: 0.5px;
    line-height: 16px;
    text-transform: uppercase;
    cursor: pointer;
  }

  .delivery-date{
    font-size:17px;
  }
`;

const CartLineItem = ({ item, first }) => {
  const { cartDispatch } = useContext(CartContext);

  const {
    handle,
    parentProductTitle,
    title,
    image,
    price,
    deliveryDate,
    timeSlot
  } = item;

  const reformatDate = val => {
    if(!val) return null;
    var days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
    let arr =[];
    let dateString = val.toDateString();
    if(val.getDay() === 0) return null;
    let obj1 = dateString.split(" ").slice(1,3);
    arr.push(days[val.getDay()] + ",");
    arr.push(...obj1);
    return arr.join(" ");
  }

  return (
    <StyledWrapper first={first} className="row py-4">
      <div className="col-5 col-lg-2">
        <Link to={`shop/${handle}`}>
          <img src={image} alt="" className="img-fluid w-100" />
        </Link>
      </div>
      <div className="col-7 col-lg-10">
        <div className="row">
          <div className="col-12 col-lg-3">
            <Link to={`shop/${handle}`}>
              <h2 className="parent-product-title">{parentProductTitle}</h2>
            </Link>
            {title !== "Default Title" && (
              <p className="variant-title">{title}</p>
            )}
          </div>
          <div className="col-12 col-lg-3">
                {deliveryDate !== undefined ? <div className="row">
                  <div className="col-lg-12">Delivery Date</div>
                  <div className="col-lg-12 delivery-date"><p>{reformatDate(deliveryDate)} | {timeSlot}</p></div> 
                </div>: <></>}
          </div>
          <div className="col-12 col-lg-3 text-lg-right my-4 my-lg-0">
            <div className="quantity">
              <QuantityEditor 
                quantity={item.quantity}
                onDecrement={() => cartDispatch({ type: "REDUCE_ITEM", payload: item.id })}
                onIncrement={() => cartDispatch({ type: "ADD_ITEM", payload: {...item, quantity: 1} })}
              />
            </div>
          </div>
          <div className="col-12 col-lg-3 text-lg-right">
            <p className="price">{stringifyPrice(price)}</p>
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <span
              className="remove"
              onClick={() => cartDispatch({ type: "DELETE_ITEM", payload: item.id })}
            >
              REMOVE
            </span>
          </div>
        </div>
      </div>
    </StyledWrapper>
  );
};

export default CartLineItem;

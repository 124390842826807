import React, { useContext } from "react";
import { CartContext } from "../context/CartContext";
import { Link } from "react-router-dom";
import CartIcon from "../../assets/images/cart.svg";
import styled from "styled-components";

const StyledWrapper = styled.div`
  margin-top: -12px;
  position: relative;
  display: inline-block;
  a {
    position: relative;
    margin: 0 !important;

    /* Hiding underline effect applied to other cart links */
    &::before {
      display: none !important;
    }
  }
  img {
    width: 25px;
  }
  div.cart-count {
    background-color: ${props => props.theme["sundance"]};
    border-radius: 100%;
    width: 20px;
    height: 20px;
    color: white;
    border: none;
    font-size: 12px;
    line-height: 20px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    padding: 5px;
    letter-spacing: 0px;

    position: absolute;
    left: 50%;
    bottom: -100%;
  }
`;

const CartBadge = () => {
  const { items } = useContext(CartContext);

  return (
    <StyledWrapper>
      <Link to="/cart">
        <img src={CartIcon} alt="cart" />
        {Object.keys(items).length > 0 && <div className="cart-count">{Object.keys(items).length}</div>}
      </Link>
    </StyledWrapper>
  );
};

export default CartBadge;
